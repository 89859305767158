import React from 'react';

import { Link } from 'react-router-dom';

import links from '../../routes/superAdminLinks';
import SidebarLink from './SidebarLink';
import MiniSidebarLink from './MiniSidebarLink';

import  "./sidebar.css";

function MiniSidebar({links}) {

    console.log("super Admin Links", links);
    
    return (
        <>
        
        <div className= "mini-sidebar-wrapper">
        {/* Side bar logo */}
        <div className = "sidebar-logo">
            {/* <Link to="/"><img src = "/images/New_logo_CSPRO_Brandon.png" /></Link>  */}
        </div>
        {/* Side bar links */}
        <div className = "mini-sidebar-link-wrapper">
            {/* Showing links below section by section */}
            {/* Main section */}
            <ul>
                {links.main.map((link,id) => (

                    <MiniSidebarLink 
                    key={id} 
                    to={link.to} 
                    title={link.title} 
                    icon={link.icon} 
                    nested={link.nested} 
                    nestedlinks={link.nestedlinks}
                    nestedIcon = {link.nestedIcon}>
                    </MiniSidebarLink>
                ))}
            </ul>
            
            {/* Activities section links */}
            {/* <div className = "link-section">
                <h1>ACTIVITIES</h1>
            </div> */}

            <ul>
                {links.Activities.map((link,id) => (

                    <MiniSidebarLink 
                    key={id} 
                    to={link.to} 
                    title={link.title} 
                    icon={link.icon} 
                    nestedlinks={link.nestedlinks}>
                        
                    </MiniSidebarLink>
                ))}
            </ul>
           
           {/* Management section links */}
            {/* <div className = "link-section">
                <h1>MANAGEMENT</h1>
            </div> */}
            <ul>
                {links.Management.map((link,id) => (
                    <MiniSidebarLink key={id} to={link.to} title={link.title} icon={link.icon} nestedlinks={link.nestedlinks}>
                        
                    </MiniSidebarLink>
                ))}
            </ul>
            
            {/* Info section links */}
            {/* <div className = "link-section">
                <h1>INFO</h1>
            </div> */}
            <ul>
                {links.Info.map((link,id) => (
                    <MiniSidebarLink key={id} to={link.to} title={link.title} icon={link.icon} nestedlinks={link.nestedlinks}>
                        
                    </MiniSidebarLink>
                ))}
            </ul>
            
            {/* Support section links */}
            {/* <div className = "link-section">
                <h1>ASK FOR SUPPORT</h1>
            </div> */}
            <ul>
                {links.Support.map((link,id) => (
                    <MiniSidebarLink key={id} to={link.to} title={link.title} icon={link.icon} nestedlinks={link.nestedlinks}>
                        
                    </MiniSidebarLink>
                ))}
            </ul>
        </div>
        </div>   
        </>
    )
}

export default MiniSidebar

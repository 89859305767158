import { DateTime } from "luxon";
import {store} from "react-notifications-component";
import { DISCLAIMER, US_ZONE,NORT_AMERICA_ZONE,CANADA_ZONE, MM_DD_YEAR, DATE__DISPLAY_FORMAT, NOTIFCATION } from "./constants";

export const getAuthUser = () => {
    let token = localStorage.getItem("access_token");
    if(token){
        return JSON.parse(token).user;
    } else{
        return false;
    }
}

export const getToken = () => {
    let token = localStorage.getItem("access_token");
    if(token){
        return JSON.parse(token).access_token;
    } else{
        return false;
    }
}
export const removeToken = () => {
    localStorage.removeItem('access_token')
}

export const getDisclaimer = () => {
    let disclaimerLocal = localStorage.getItem(DISCLAIMER);
    let disclaimerSession = sessionStorage.getItem(DISCLAIMER);
    if(disclaimerLocal || disclaimerSession){
        return false;
    } else{
        return true;
    }
}

export const setDisclaimerLocal = () => {
    localStorage.setItem(DISCLAIMER, JSON.stringify({show: false}));
}
export const setDisclaimerSession = () => {
    sessionStorage.setItem(DISCLAIMER, JSON.stringify({show: false}));
}

export const setToken = (access_token) => {
    localStorage.setItem("access_token", JSON.stringify(access_token));
}

export const success_alert = (message, title) => {
    store.addNotification({
        title: title ? title : "",
        message: message,
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const error_alert = (message, title) => {
    store.addNotification({
        title: title ? title : "",
        message: message,
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const warning_alert = (message, title) => {
    store.addNotification({
        title: title ? title : "",
        message: message,
        type: "warning",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}



export function timeDifference(current, previous) {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
        const seconds = Math.round(elapsed/1000);
        return  + seconds >= 0 ? `${seconds} seconds ago` : "0 seconds ago";   
    }

    else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + " minutes ago";   
    }

    else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + " hours ago";   
    }

    else if (elapsed < msPerMonth) {
        return 'approximately ' + Math.round(elapsed/msPerDay) + " days ago";   
    }

    else if (elapsed < msPerYear) {
        return 'approximately ' + Math.round(elapsed/msPerMonth) + "months ago";   
    }

    else {
        return 'approximately ' + Math.round(elapsed/msPerYear ) + "years ago";   
    }
}

export const dynamicColors = () => {
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
 };

export const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
 }
 

export const b64toBlob=(dataURI)=> {
    
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
}

export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

export const setDate = (dateObj) => {
    localStorage.setItem("date", dateObj);
}
export const getDateFromLocalStorage = () => {
    let dateObj = localStorage.getItem("date");
    if(dateObj){
        return DateTime.fromISO(dateObj);
    } else{
        return false;
    }
}
export const removeDate = () => {
    localStorage.removeItem('date')
}

export const getDateFormat=(zone)=>{
    if(zone===US_ZONE ||zone===CANADA_ZONE || zone===NORT_AMERICA_ZONE)
    {
        return MM_DD_YEAR
    }else{
        return DATE__DISPLAY_FORMAT
    }
}


export const getFlooredFixed=(v, d)=>{
    return (Math.floor(v * Math.pow(10,1)) / Math.pow(10, 1)).toFixed(1);
}


export const removeUnderScoreAddSpaceCaptialFirstLetter=(slug)=>{  // use in stats, and for unslugify 
  if (typeof slug !== "string" || slug.trim() === "" ) {
    console.error("Error: slug parameter must be a string");
    return "";
  }
  const words = slug.split("_");
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(" ");

}




export const hideNotification=()=>{
    localStorage.setItem(NOTIFCATION, JSON.stringify({show: true}));
}
export const showNotification=()=>{
    localStorage.setItem(NOTIFCATION, JSON.stringify({show: false}));
}


export const slugify= (str)=>{
    if(str){
       return str
      .normalize('NFKD') // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-'); // remove consecutive hyphens
    }else{
        return false
    }
  }
  

// Libraries Import
import React, {useState,Suspense} from 'react';
import {Switch,Route} from 'react-router-dom';
import {useSelector} from 'react-redux';

// Routes Import
import AuthRoutes from '../routes/AuthRoute';
import {superAdminLinks} from '../routes/superAdminLinks';
import {localAdminLinks} from '../routes/LocalAdminLinks';
import {salesManagerLinks} from '../routes/SalesManagerLinks';
import {technicalAdminLinks} from '../routes/technicalAdminLinks';
import TechnicalAdminRoutes from '../routes/TechnicalAdminRoutes';
import SuperAdminRoutes from '../routes/SuperAdminRoutes';
import LocalAdminRoutes from '../routes/LocalAdminRoutes';
import SalesManagerRoutes from '../routes/SalesManagerRoutes';

// Components Import
import Sidebar from '../components/Sidebar/Sidebar';
import MiniSidebar from '../components/Sidebar/MiniSidebar';
import TopBar from '../components/TopBar/Topbar';
import Footer from '../components/Footer';
import {FullScreenLoading} from "../components/Loading";

// constants
import {SUPER_ADMIN, LOCAL_ADMIN, TECHNICAL_ADMIN, SALES_MANAGER} from "../constants";
import HaartSurgeonProcedure from '../components/Releases/HaartProceduresRelease';
// import NewReleasePopup from '../pages/NewRealse/Popup';
// constants

// const DisclaimerPopup = React.lazy(() => import('../components/Releases/Disclaimer'));
// const ProctorshipTrainingLogPopup = React.lazy(() => import('../components/Releases/ProctorshipTrainingLogRelease'));
const EventFeedbackForm = React.lazy(() => import("../pages/Activities/SpeakingEvent/RightSide/SpeakingInformation/EventFeedBackForm/EventFeedbackForm"));
const EventFeedbackSummary = React.lazy(() => import("../pages/Activities/SpeakingEvent/RightSide/SpeakingInformation/EventFeedBackForm/EventFeedbackSummary"));
// By zain





function BaseRoute() {
  const [asideVisibility, setAsideVisibility] = useState(false);

  const [isFeedback, setIsFeedback] = useState(false);

  const [userInfoCard, setUserInfoCard] = useState(false);


  const user = useSelector(state => state.user);
  
  let links = user.role === SUPER_ADMIN ?
    superAdminLinks
  : user.role === LOCAL_ADMIN ?
    localAdminLinks
  : user.role === TECHNICAL_ADMIN ?
    technicalAdminLinks
  : salesManagerLinks

  // if(user.role === SUPER_ADMIN){
  //   links = superAdminLinks;
  // }
  // else if(user.role === LOCAL_ADMIN){
  //   links = localAdminLinks;
  // }
  // else if(user.role === TECHNICAL_ADMIN){
  //   links = technicalAdminLinks;
  // }
  // else{
  //   links = salesManagerLinks;
  // }

  return (  

    <>
      {
        user ?
        <>
          <Sidebar isFeedback={isFeedback} hideSidebar={setAsideVisibility} visible={asideVisibility} links={links}/>
        </>  
        :
        null
      }
      <Suspense fallback={<FullScreenLoading/>}>
      <Switch>

          <Route path="/feedback/:id" exact>
              <EventFeedbackForm setIsFeedback={setIsFeedback} />
          </Route>
          <Route path="/feedbackSummary/:id" exact>
              <EventFeedbackSummary setIsFeedback={setIsFeedback} />
          </Route>

                    


          {!user ? (
            <>
            <div className="block w-full h-full flex flex-col justify-between">
              <div className="flex-grow relative">
                <AuthRoutes setIsFeedback={setIsFeedback} /> 
              </div>
            </div> 

            </>
          )
          :
            null
          }

          {user ? (
            <>
            <div className="block w-full h-full flex flex-col justify-between pl-0 lg:pl-250 2xl:pl-300">
              {/* <DisclaimerPopup /> */}
              {/* <NewReleasePopup/> */}
              {/* <ProctorshipTrainingLogPopup /> */}
              <HaartSurgeonProcedure/>

              <TopBar userInfoCard={userInfoCard} setUserInfoCard={setUserInfoCard} asideVisibility={asideVisibility} setAsideVisibility={setAsideVisibility}/>
              <div className="flex-grow relative">
                {
                  (user.role === SUPER_ADMIN) ? (
                    <SuperAdminRoutes />
                  )
                  :
                  (user.role === LOCAL_ADMIN)?
                    <LocalAdminRoutes />
                  :
                  (user.role === TECHNICAL_ADMIN)?
                    <TechnicalAdminRoutes />
                  :
                  (user.role === SALES_MANAGER)?
                    <SalesManagerRoutes />
                  :
                  <SuperAdminRoutes  />
                }
              </div>
              <Footer/>
            </div>
              
            </>
          ): null}

        </Switch>
        </Suspense>
        
      </>
  )
}

export default BaseRoute

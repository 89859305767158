import {createSlice} from "@reduxjs/toolkit";

const activeTabs = [
    {
        value: "mics_course",
        label: "NEW MICS COURSE",
        updatedLabel: "NEW MICS COURSE",
        visible: true
    },
    {
        value: "course-type",
        label: "TYPE OF COURSE",
        updatedLabel: "",
        visible: true
    },
    {
        value: "preceptorship-date",
        label: "Preceptorship Date",
        updatedLabel: "",
        visible: true,
    },
    {
        value: "first-proctorship",
        label: "First Proctorship",
        updatedLabel: "First Proctorship",
        visible: true,
    },
    {
        value: "certification-proctorship",
        label: "Certification Proctorship",
        updatedLabel: "Certification Proctorship",
        visible: true,
    },
    {
        value: "trainees",
        label: "Trainees",
        updatedLabel: "",
        visible: true,
    },
    {
        value: "request",
        label: "Submit Request",
        updatedLabel: "",
        visible: true,
    },
]


const initialState = {
    activeTab: 1, // points to the index of steps array. it can never go to the 0th_index/1st_step step REMEMBER THAT!!!
    tabs: activeTabs,

    // we will populate these dynamically when moving between sections
    type_of_course: false,
    
    date_preceptorship: {
        start_date: "",
        end_date: "",
        coe: "",
        note: "",

        booking_option: true, //this option is used to show the calender of created activities or whether we want to create a new activity
        num_of_trainees: 0, //this option is used to show number of seats available on trainees step
    },

    first_proctorship: {
        start_date: "",
        end_date: "",
        country: "",
        hospital: "",
        suggested_hotel: "",
        number_of_cases: "",
        procedure_time: ""
    },
    
    certification_proctorship: {
        start_date: "",
        end_date: "",
        country: "",
        hospital: "",
        suggested_hotel: "",
        number_of_cases: "",
        procedure_time: ""
    },

    skip_first_proctorship: false,
    skip_certification_proctorship: false,

    trainees: [
        
    ],
    // we will populate these dynamically when moving between sections

    //this loading and error are only relevant for submitting the api
    loading: false,
    error: false,
    //this loading and error are only relevant for submitting the api

    activity_id: 0, //this state will be set after our course is created successfully and we want to redirect to it.
}

const micsCourseSlice = createSlice({
    name: "mics_course",
    initialState,
    reducers: {
        // set forward direction for animation
        forward_direction: (state, action) => {
            state.direction = 1;
            return state;
        },
        // set backward direction for animation
        backward_direction: (state, action) => {
            state.direction = 0;
            return state;
        },

        // proceed to next step
        increment_tab: (state, action) => {
            state.activeTab = state.activeTab + 1;
            return state
        },

        set_tab: (state, action) => {
            state.activeTab = action.payload;
            return state;
        },
        // go to to previous step
        decrement_tab: (state, action) => {
            state.activeTab = state.activeTab - 1;
            return state
        },
        // show all tabs
        set_visible_tabs: (state, action) => {
            // visible all steps after the region tabs
            state.tabs = state.tabs.map(tab => {
                tab.visible = true
                return tab
            });
            return state
        },
        // hide all tabs except the first two
        set_invisible_tabs: (state, action) => {
            // visible all steps after the region tabs
            state.tabs = state.tabs.map((tab, index) => {
                if(index > 1){
                    tab.visible = false;
                }
                return tab
            });
            return state
        },
        
        // update/set the label of the current active tab
        set_current_label: (state, action) => {
            state.tabs[state.activeTab].updatedLabel = action.payload; //anything we pass to this action updates the name of the current active tab.
        },
        
        // setting data for type
        set_type_of_course: (state, action) => {
            state.type_of_course = action.payload;
            return state
        },

        set_skip_first_proctorship: (state, action) => {
            state.skip_first_proctorship = !state.skip_first_proctorship;
            return state
        },
        
        set_skip_certification_proctorship: (state, action) => {
            state.skip_certification_proctorship = !state.skip_certification_proctorship;
            return state
        },

        //dates reducers below
        set_date_preceptorship_field: (state, action) => {
            state.date_preceptorship = {...state.date_preceptorship, ...action.payload};
            return state;
        },
        set_first_proctorship_field: (state, action) => {
            state.first_proctorship = {...state.first_proctorship, ...action.payload};
            return state;
        },
        set_certification_proctorship_field: (state, action) => {
            state.certification_proctorship = {...state.certification_proctorship, ...action.payload};
            return state;
        },
        //dates reducers above


        // trainees reducers below
        add_trainee: (state, action) => {
            state.trainees.push(action.payload);
            return state;
        },
        update_trainee: (state, action) => {
            state.trainees[action.payload.index] = action.payload.data;
            return state;
        },
        delete_trainee: (state, action) => {
            const index = action.payload;
            if (index > -1) {
                state.trainees.splice(index, 1);
            }
            return state;
        },
        // trainees reducers above

        // reset reducers below
        
        reset_date_preceptorship: (state, action) => {
            state.date_preceptorship = initialState.date_preceptorship;
            return state
        },
        reset_first_proctorship: (state, action) => {
            state.first_proctorship = initialState.first_proctorship;
            return state
        },
        reset_certification_proctorship: (state, action) => {
            state.certification_proctorship = initialState.certification_proctorship;
            return state
        },
        
        reset_trainees: (state, action) => {
            state.trainees = initialState.trainees;
            return state
        },

        reset_all: (state, action) => {
            state = initialState;
            return state
        },
        // reset reducers above
        
        
        set_loading: (state, action) => {
            state.loading = action.payload;
            return state
        },
        set_error: (state, action) => {
            state.error = action.payload;
            return state
        },

        set_activity_id: (state, action) => {
            state.activity_id = action.payload;
        }

    }   
})

export const {
    forward_direction,
    backward_direction,
    set_tab,
    increment_tab,
    decrement_tab,
    set_visible_tabs,
    set_invisible_tabs,
    
    set_type_of_course,

    set_current_label,

    set_date_preceptorship_field,
    set_first_proctorship_field,
    set_certification_proctorship_field,
    
    set_skip_first_proctorship,
    set_skip_certification_proctorship,

    add_trainee,
    delete_trainee,
    update_trainee,

    reset_date_preceptorship,
    reset_first_proctorship,
    reset_certification_proctorship,
    reset_trainees,
    reset_all,

    set_loading,
    set_error,
    set_activity_id

} = micsCourseSlice.actions

export default micsCourseSlice.reducer
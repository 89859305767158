import React, {useState, useEffect} from 'react'
import {FaCaretLeft, FaCaretDown} from "react-icons/fa";
import {NavLink} from "react-router-dom"


const ThirdLevelLink = ({closeSidebar, second_link, setParentHeight, parentHeight}) => {
    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState(false);

    useEffect(() => {
    
        if(parentHeight === 0){
            setHeight(0);
            setOpen(false);
        }
        // else if(parentHeight === parentHeight +(second_link.links.length * 40)){
        //     setParentHeight(prev => prev - (second_link.links.length * 40))
        // }

    },[parentHeight])

    const increaseHeight = () => {
        setOpen(true);
        setHeight(second_link.links.length * 40);
        setParentHeight(prev => prev +(second_link.links.length * 40))
    }
    
    const decreaseHeight = () => {
        setOpen(false);
        setHeight(0);
        setParentHeight(prev => prev - (second_link.links.length * 40))
    }

    return (
        <div className="w-12 m-0 w-full" >
            <p onClick={() => !open ? increaseHeight() : decreaseHeight()} className="hover:text-white cursor-pointer pl-5 pr-3 m-0 no-underline text-gray-light sidebar-link-height flex items-center justify-between">
                <span className="text-sm flex items-center">
                        <span className="text-base mr-2">
                            {second_link.icon}
                        </span>
                        {second_link.title}
                </span>
                <div className="text-sm flex-shrink-0">
                {open ? <FaCaretDown/> : <FaCaretLeft/>}
                </div>
            </p>
            <div className={`overflow-hidden relative transition-all`} style={{height: `${height}px`}}>
                
                {/* second level */}
                {second_link.links.map(third_link => (
                    <NavLink to={third_link.to} onClick={closeSidebar} className="hover:text-white pl-12 pr-3 m-0 no-underline text-gray-light sidebar-link-height flex items-center justify-between">
                        <span className="text-sm flex items-center">
                            <span className="text-base mr-2">
                                {third_link.icon}
                            </span>
                            {third_link.title}
                        </span>
                    </NavLink>
                ))}
                {/* second level */}
                
                
            </div>
            
        </div>
    )
}

export default ThirdLevelLink

import {createSlice} from "@reduxjs/toolkit";

const activeTabs = [
    {
        value: "speaking_events",
        label: "SPEAKING EVENTS",
        updatedLabel: "SPEAKING EVENTS",
        visible: true
    },
    {
        value: "local-global",
        label: "LOCAL OR GLOBAL",
        updatedLabel: "",
        visible: true
    },
    {
        value: "event-detail",
        label: "Add Event Detail",
        updatedLabel: "",
        visible: false,
    },
    
    {
        value: "speech",
        label: "Speech",
        updatedLabel: "",
        visible: false,
    },
    {
        value: "request",
        label: "Submit Request",
        updatedLabel: "",
        visible: false,
    },
]


const initialState = {
    activeTab: 1, // points to the index of steps array. it can never go to the 0th_index/1st_step step REMEMBER THAT!!!
    tabs: activeTabs,

    // we will populate these dynamically when moving between sections
    region: false,
    
    event_detail: {
        name: "",
        location: "",
        start_date: "",
        end_date: "",
        event_type: "",
        country: "",
        solution: "",
        participants: "",
        language: "",
        region: "",
        no_speach: false,
        note: ""
    },
    add_speech: {
        speaker_role: "",
        specialty: "",
        title: "",
        duration: "",
        speech_focus_id: "",
        focus_not_listed: "",
        proctor_id: "",
        other_proctor: "",
        new_mics: false,
        enable_invoice:"",
        name_employee: "",
        depart_employee: "",
        note: ""
    },
    agenda: null,
    speech: [
       
    ],
    // we will populate these dynamically when moving between sections

    //this loading and error are only relevant for submitting the api
    loading: false,
    error: false,
    //this loading and error are only relevant for submitting the api

    activity_id: 0, //this state will be set after our proctorship is created successfully and we want to redirect to it.
}

const speakingEventSlice = createSlice({
    name: "speaking_event",
    initialState,
    reducers: {
        // set forward direction for animation
        forward_direction: (state, action) => {
            state.direction = 1;
            return state;
        },
        // set backward direction for animation
        backward_direction: (state, action) => {
            state.direction = 0;
            return state;
        },

        // proceed to next step
        increment_tab: (state, action) => {
            state.activeTab = state.activeTab + 1;
            return state
        },

        set_tab: (state, action) => {
            state.activeTab = action.payload;
            return state;
        },
        // go to to previous step
        decrement_tab: (state, action) => {
            state.activeTab = state.activeTab - 1;
            return state
        },
        // show all tabs
        set_visible_tabs: (state, action) => {
            // visible all steps after the region tabs
            state.tabs = state.tabs.map(tab => {
                tab.visible = true
                return tab
            });
            return state
        },
        // hide all tabs except the first two
        set_invisible_tabs: (state, action) => {
            // visible all steps after the region tabs
            state.tabs = state.tabs.map((tab, index) => {
                if(index > 1){
                    tab.visible = false;
                }
                return tab
            });
            return state
        },
        
        // update/set the label of the current active tab
        set_current_label: (state, action) => {
            state.tabs[state.activeTab].updatedLabel = action.payload; //anything we pass to this action updates the name of the current active tab.
        },
        
        // setting data for region
        region_append_data: (state, action) => {
            state.region = action.payload;
            return state
        },
        // setting data for products
        product_append_data: (state, action) => {
            state.product = action.payload;
            return state
        },

        //training reducers below
        set_training_field: (state, action) => {
            state.training = {...state.training, ...action.payload};
            return state;
        },
        //training reducers above

        //event_detail reducers below
        set_event_detail_field: (state, action) => {
            state.event_detail = {...state.event_detail, ...action.payload};
            return state;
        },
        //event_detail reducers above


        // speech reducers below
        set_add_speech_field: (state, action) => {
            state.add_speech = {...state.add_speech, ...action.payload};
            return state;
        },
        set_add_speech_initial: (state, action) => {
            state.add_speech = action.payload
            return state;
        },
        
        reset_add_speech: (state, action) => {
            state.add_speech = initialState.add_speech;
            return state;
        },
        add_speech: (state, action) => {
            state.speech.push(action.payload);
            return state;
        },
        update_speech: (state, action) => {
            state.speech[action.payload.index] = action.payload.data;
            return state;
        },
        delete_speech: (state, action) => {
            const index = action.payload;
            if (index > -1) {
                state.speech.splice(index, 1);
            }
            return state;
        },
        
        // speech reducers above

        // reset reducers below
                
        reset_event_detail: (state, action) => {
            state.event_detail = initialState.event_detail;
            return state
        },
        reset_speech: (state, action) => {
            state.speech = initialState.speech;
            return state
        },

        reset_all: (state, action) => {
            state = initialState;
            return state
        },
        // reset reducers above
        
        
        set_loading: (state, action) => {
            state.loading = action.payload;
            return state
        },
        set_error: (state, action) => {
            state.error = action.payload;
            return state
        },

        set_activity_id: (state, action) => {
            state.activity_id = action.payload;
        }



    }   
})

export const {
    forward_direction,
    backward_direction,
    set_tab,
    increment_tab,
    decrement_tab,
    set_visible_tabs,
    set_invisible_tabs,
    
    region_append_data,
    product_append_data,


    set_current_label,

    set_training_field,
    set_event_detail_field,
    
    proctors_append_data,
    
    set_add_speech_field,
    set_add_speech_initial,
    reset_add_speech,
    add_speech,
    update_speech,
    delete_speech,

    reset_speech,
    reset_event_detail,
    reset_all,

    set_loading,
    set_error,
    set_activity_id

} = speakingEventSlice.actions

export default speakingEventSlice.reducer
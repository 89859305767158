import {createSlice} from "@reduxjs/toolkit";

const activeTabs = [
    {
        value: "proctorship",
        label: "PROCTORSHIP",
        updatedLabel: "PROCTORSHIP",
        visible: true
    },
    {
        value: "local-global",
        label: "LOCAL OR GLOBAL",
        updatedLabel: "",
        visible: true
    },
    {
        value: "is_online",
        label: "type",
        updatedLabel: "",
        visible: false
    },
    {
        value: "product",
        label: "Product",
        updatedLabel: "",
        visible: false,
    },
    {
        value: "corcym",
        label: "TRAINER",
        updatedLabel: "",
        visible: false
    },
    {
        value: "training",
        label: "Training Type",
        updatedLabel: "",
        visible: false,
    },
    {
        value: "date",
        label: "Date",
        updatedLabel: "",
        visible: false,
    },
    {
        value: "proctor",
        label: "Proctor",
        updatedLabel: "",
        visible: false,
    },
    {
        value: "trainees",
        label: "Trainees",
        updatedLabel: "",
        visible: false,
    },
    {
        value: "request",
        label: "Submit Request",
        updatedLabel: "",
        visible: false,
    },
]


const initialState = {
    activeTab: 1, // points to the index of steps array. it can never go to the 0th_index/1st_step step REMEMBER THAT!!!
    tabs: activeTabs,

    // we will populate these dynamically when moving between sections
    local_gloabl: {},
    product: {},
    is_online: null,
    // This field added by zain, par ab Saram ka kabza he... this is to check whether we want Clinical Trainers or proctors
    is_corcym: false,
    // This field added by zain, par ab Saram ka kabza he... this is to check whether we want Clinical Trainers or proctors
    training: {
        type_of_training: "",
        type_of_advanced_training: "",

        proctorship: "",
        
        total_implants: "",
        other_advanced_training_total_implants:"",
        etq: "",
        specify_issue: "",

        other_advanced_training: "",
        other_advanced_training_option: "",
        refresh_training_option:"",
        other_advance_training_note:"",


        note: "",
        first_proctorship_num: ""

    },
    date: {
        start_date: "",
        end_date: "",
        country: "",
        hospital: "",
        suggested_hotel: "",
        number_of_cases: "",
        procedure_time: "",
        ct_scan: ""
    },
    proctor: {
        requirements: "",
        first: false,
        second: false,
        third: false,
    },
    trainees: [
        // {
        //     title: "",
        //     name: "",
        //     surname: "",
        //     rep: "",
        //     avr_hospital: "",
        //     avr_trainees: "",
        //     current_approach: "",
        //     note: "",
        //     interest_invasive: false,
        //     completed: false //only set this to true if all the fields are filled for a particular trainee object
        // }
    ],
    // we will populate these dynamically when moving between sections

    //this loading and error are only relevant for submitting the api
    loading: false,
    error: false,
    //this loading and error are only relevant for submitting the api

    activity_id: 0, //this state will be set after our proctorship is created successfully and we want to redirect to it.
}

const proctorshipSlice = createSlice({
    name: "proctorship",
    initialState,
    reducers: {
        // set forward direction for animation
        forward_direction: (state, action) => {
            state.direction = 1;
            return state;
        },
        // set backward direction for animation
        backward_direction: (state, action) => {
            state.direction = 0;
            return state;
        },

        // proceed to next step
        increment_tab: (state, action) => {
            state.activeTab = state.activeTab + 1;
            return state
        },

        set_tab: (state, action) => {
            state.activeTab = action.payload;
            return state;
        },
        // go to to previous step
        decrement_tab: (state, action) => {
            state.activeTab = state.activeTab - 1;
            return state
        },
        // show all tabs
        set_visible_tabs: (state, action) => {
            // visible all steps after the region tabs
            state.tabs = state.tabs.map(tab => {
                tab.visible = true
                return tab
            });
            return state
        },
        // hide all tabs except the first two
        set_invisible_tabs: (state, action) => {
            // visible all steps after the region tabs
            state.tabs = state.tabs.map((tab, index) => {
                if(index > 1){
                    tab.visible = false;
                }
                return tab
            });
            return state
        },
        
        // update/set the label of the current active tab
        set_current_label: (state, action) => {
            state.tabs[state.activeTab].updatedLabel = action.payload; //anything we pass to this action updates the name of the current active tab.
        },
        
        // setting data for region
        region_append_data: (state, action) => {
            state.local_gloabl = action.payload;
            return state
        },
        
        // setting data for isOnline
        set_is_online: (state, action) => {
            state.is_online = action.payload;
            return state
        },
        // setting data for corcym export or proctor
        set_corcym_or_proctor: (state, action) => {
            state.is_corcym = action.payload;
            return state
        },

        // setting data for products
        product_append_data: (state, action) => {
            state.product = action.payload;
            return state
        },

        proctors_set_requirements: (state, action) => {
            state.proctor.requirements = action.payload
            return state;
        },

        //training reducers below
        set_training_field: (state, action) => {
            state.training = {...state.training, ...action.payload};
            return state;
        },
        //training reducers above

        //dates reducers below
        set_dates_field: (state, action) => {
            state.date = {...state.date, ...action.payload};
            return state;
        },
        //dates reducers above

        // proctor reducers below
        proctors_append_data: (state, action) => {
            state.proctor = {...state.proctor, ...action.payload}
            return state;
        },
        // proctor reducers above


        // trainees reducers below
        add_trainee: (state, action) => {
            state.trainees.push(action.payload);
            return state;
        },
        update_trainee: (state, action) => {
            state.trainees[action.payload.index] = action.payload.data;
            return state;
        },
        delete_trainee: (state, action) => {
            const index = action.payload;
            if (index > -1) {
                state.trainees.splice(index, 1);
            }
            return state;
        },
        // trainees reducers above

        // reset reducers below
        reset_corcym_or_proctor: (state, action) => {
            state.is_corcym = initialState.is_corcym;
            return state
        },
        reset_products: (state, action) => {
            state.product = initialState.product;
            return state
        },
        reset_is_online: (state, action) => {
            state.is_online = initialState.is_online;
            return state
        },
        reset_training: (state, action) => {
            state.training = initialState.training;
            return state
        },
        reset_date: (state, action) => {
            state.date = initialState.date;
            return state
        },
        reset_proctor: (state, action) => {
            state.proctor = initialState.proctor;
            return state
        },
        
        reset_trainees: (state, action) => {
            state.trainees = initialState.trainees;
            return state
        },

        reset_all: (state, action) => {
            state = initialState;
            return state
        },
        // reset reducers above
        
        
        set_loading: (state, action) => {
            state.loading = action.payload;
            return state
        },
        set_error: (state, action) => {
            state.error = action.payload;
            return state
        },

        set_activity_id: (state, action) => {
            state.activity_id = action.payload;
        }



    }   
})

export const {
    forward_direction,
    backward_direction,
    set_tab,
    increment_tab,
    decrement_tab,
    set_visible_tabs,
    set_invisible_tabs,
    
    region_append_data,
    product_append_data,


    set_current_label,

    set_training_field,
    set_dates_field,
    delete_trainee,

    proctors_append_data,
    proctors_set_requirements,

    add_trainee,
    update_trainee,

    reset_corcym_or_proctor,
    reset_products,
    reset_training,
    reset_date,
    reset_proctor,
    reset_trainees,
    reset_all,

    set_loading,
    set_error,
    set_activity_id,

    set_is_online,
    set_corcym_or_proctor,
    reset_is_online

} = proctorshipSlice.actions

export default proctorshipSlice.reducer
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: true,
    error: false,
    data: [
        // data goes here
    ],
    currentPage: 1,
    count: false,
    refreshPage: 1, //our component will refresh everytime we update this field
}

const userListSlice = createSlice({
    name: "user_list",
    initialState,
    reducers: {
        user_list_load: (state) => {
            state.loading = true;
            state.networkError = false;
            return state;
        },
        user_list_success: (state, action) => {
            state.loading = false;
            state.count = action.payload.count;
            state.data = action.payload.data;
            return state;
        },
        user_list_error: (state, action) => {
            state.loading = false;
            state.error = action.payload
            return state;
        },

        page_change: (state, action) => {
            state.currentPage = action.payload;
            return state
        },

        refresh_page: (state, action) => {
            state.refreshPage = state.refreshPage + 1;
            return state
        },
    }
})

export const {
    user_list_load,
    user_list_success,
    user_list_error,
    user_change,
    refresh_page,
} = userListSlice.actions;

export default userListSlice.reducer;

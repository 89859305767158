import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    redirectTo: '/hospitals'
}

const baseEditHospitalsSlice = createSlice({
    name: "base_edit_hospitals",
    initialState,
    reducers: {
        set_redirect_to: (state, action) => {
            state.redirectTo = action.payload;
            return state;
        },
    }
})


export const {
    set_redirect_to,
} = baseEditHospitalsSlice.actions;

export default baseEditHospitalsSlice.reducer
import { createSlice } from '@reduxjs/toolkit';
import { validateEmail } from '../../utils';

const initialState = [
    
];

const ProctorshipCertificationSlice = createSlice({
    name: "proctorship_certification",
    initialState,
    reducers: {
        set_initial_proctorship_certificaiton: (state, action) => (action.payload),
        set_email: (state, action) => {
            const value = action.payload.value;
            const index = action.payload.index;
            
            state[index].email = value;
            if (validateEmail(value)) {
                state[index].error = false;
            } else if (!value || !value.trim()) {
                state[index].error = "Required.";
            }
            else {
                state[index].error = "Please enter a valid email.";
            }
            return state;
        },
        set_date: (state, action)=> {
            const {traineeIndex, caseIndex, date} = action.payload;
            state[traineeIndex].cases[caseIndex].date = date;
            return state;
        },
        set_approach: (state, action)=> {
            const {traineeIndex, caseIndex, approach} = action.payload;
            state[traineeIndex].cases[caseIndex].surgical_approach = approach;
            return state;
        },
        add_case: (state, action)=> {
            const {traineeIndex, newCase} = action.payload;
            state[traineeIndex].cases.push(newCase)
            return state;
        },
        delete_case: (state, action)=> {
            const {caseIndex, traineeIndex} = action.payload;
            state[traineeIndex].cases.splice(caseIndex, 1); 
            return state;
        },
        set_error: (state, action)=> {
            const {index, error} = action.payload;
            state[index].error = error; 
            return state;
        },

        reset_proctorship_certification: (state, action) => initialState
        
    }
})

export const {
    set_initial_proctorship_certificaiton,
    set_email,
    set_date,
    set_approach,
    add_case,
    delete_case,
    set_error,
    reset_proctorship_certification
} = ProctorshipCertificationSlice.actions;
export default ProctorshipCertificationSlice.reducer;

import React, {createContext, useContext, useEffect} from 'react'
import {AnimatePresence, motion} from "framer-motion";
import {FaTimes} from "react-icons/fa";

//global context for popup
export const BasePopupContext = createContext(null);

// initial state for popup
const initialState = {
    component: "",
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false
}

const BasePopup = () => {

    //extracting state from context
    const {popupState, setPopup} = useContext(BasePopupContext);
    const {component, heading, size, visible, contained, hideDefaultClose} = popupState;

    const hidePopup = () => {
        setPopup({visible: false, hideDefaultClose: false});
    }

    // this is a helper component which sets the base for a modal
    const variants = {
        initial: {
            opacity: 0,
            y: 100
        },
        enter: {
            opacity: 1,
            y: 0
        },
        exit: {
            opacity: 0,
            y: -100
        }
    }

    const title = heading ? heading : "";

    const sizeClass = size === "small" ? "w-full md:w-96 lg:w-6/12 xl:w-5/12 2xl:w-3/12" : size==="medium"? "w-full lg:w-7/12 xl:w-6/12 2xl:w-5/12 max-w-[700px]": "w-10/12";
    return (
            <AnimatePresence>
                    {visible &&
                    <PopupContent 
                        hideDefaultClose={hideDefaultClose}
                        variants={variants}
                        title={title}
                        contained={contained}
                        hideDefaultClose={hideDefaultClose}
                        hidePopup={hidePopup}
                        component={component}
                        sizeClass={sizeClass}
                        setPopup={setPopup}
                    />
                    // <div className="bg-black bg-opacity-30 left-0 top-0 fixed h-screen w-screen flex items-center justify-center z-10001">
                    //     <motion.div
                    //     transition={{type: "tween"}}
                    //     initial="initial"
                    //     animate="enter"
                    //     exit="exit"
                    //     variants={variants}
                    //     className={`${sizeClass} bg-white shadow-lg rounded-md`}>
                    //         <div className=""></div>
                    //         {/* header */}
                    //         <div className="p-5 flex items-center justify-between border-b-1">
                    //             <p className="text-2xl text-gray-textdark font-bold ">
                    //                 {title}
                    //             </p>
                    //             <div className={`text-gray-textdark text-3xl cursor-pointer ${hideDefaultClose && "hidden"}`} onClick={hidePopup}>
                    //                 <FaTimes/>
                    //             </div>
                    //         </div>
                    //         {/* header */}
                            
                    //         <div className={contained ? "px-5 py-8" : ""}>
                    //             {component}
                    //         </div>
                    //     </motion.div>
                    // </div>
                    }
            </AnimatePresence>
    )
}

const PopupContent = ({variants, title, contained, hideDefaultClose, hidePopup, component, sizeClass, setPopup}) => {

    useEffect(() => {
        return () => {
            // reset popup to initial state on unmount
            setPopup(initialState)
        }
    },[])

    return(
        <div className="bg-black bg-opacity-30 left-0 top-0 fixed h-screen w-screen flex items-start pt-16 pb-8 justify-center z-10001 overflow-y-auto">
            <motion.div
            transition={{type: "tween"}}
            initial="initial"
            animate="enter"
            exit="exit"
            variants={variants}
            className={`${sizeClass} bg-white shadow-lg rounded-md`}>
                <div className=""></div>
                {/* header */}
                <div className="p-5 flex items-center justify-between border-b-1">
                    <p className="text-lg lg:text-2xl text-gray-textdark font-bold ">
                        {title}
                    </p>
                    <div className={`text-gray-textdark text-3xl cursor-pointer ${hideDefaultClose && "hidden"}`} onClick={hidePopup}>
                        <FaTimes/>
                    </div>
                </div>
                {/* header */}
                
                <div className={contained ? "px-5 py-8" : ""}>
                    {component}
                </div>
            </motion.div>
        </div>
    )
}

export default BasePopup

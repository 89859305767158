import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: true,
    raw_data: [],
    select_data: [],
    error: false
}

const baseProductsSlice = createSlice({
    name: "base_products",
    initialState,
    reducers: {
        set_products_loading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        set_products_data: (state, action) => {
            state.raw_data = action.payload.raw_data;
            state.select_data = action.payload.select_data;
            state.loading = false;
            state.error = false;
            return state;
        },
        set_products_error: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
})


export const {
    set_products_loading,
    set_products_data,
    set_products_error,
} = baseProductsSlice.actions;

export default baseProductsSlice.reducer
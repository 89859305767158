import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    profiesional_participant_Information:[],

}

const professionalParticipant = createSlice({
    name: "proctorship",
    initialState,
    reducers: {
        set_profiesional_participant_Information: (state, action) => {
            state.profiesional_participant_Information = [...state.profiesional_participant_Information,action.payload]
            return state;
        },
        edit_profiesional_participant_Information: (state, action) => {
            state.profiesional_participant_Information[action.payload.index].speciality = action.payload.speciality
            state.profiesional_participant_Information[action.payload.index].name = action.payload.name
            state.profiesional_participant_Information[action.payload.index].tilte = action.payload.title
            state.profiesional_participant_Information[action.payload.index].mvr_case_per_year = action.payload.mvr_case_per_year
            state.profiesional_participant_Information[action.payload.index].mvr_case_per_year_by_trainee = action.payload.mvr_case_per_year_by_trainee
            state.profiesional_participant_Information[action.payload.index].current_preferential = action.payload.current_preferential
            state.profiesional_participant_Information[action.payload.index].note = action.payload.note
            state.profiesional_participant_Information[action.payload.index].interest_invasive = action.payload.interest_invasive
            state.profiesional_participant_Information[action.payload.index].hospital = action.payload.hospital
            state.profiesional_participant_Information[action.payload.index].country = action.payload.country
            return state;
        },

        delete_profesional_participant_info:(state,action)=>{
            state.profiesional_participant_Information = state.profiesional_participant_Information.filter((element,index) => index!==action.payload)  // we are getting index from action.payload
            return state
        },
        // reset reducers above
        reset_profiesional_participant_Information: (state, action) => {
            state = initialState;
            return state
        },
        // reset reducers above
        
    
    }   
})

export const {
    set_profiesional_participant_Information,
    reset_profiesional_participant_Information,
    delete_profesional_participant_info,
    edit_profiesional_participant_Information
} = professionalParticipant.actions

export default professionalParticipant.reducer
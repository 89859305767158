import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: true,
    raw_data: [],
    select_data: [],
    error: false
}

const baseHospitalsSlice = createSlice({
    name: "base_hospitals",
    initialState,
    reducers: {
        set_hospitals_loading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        set_hospitals_data: (state, action) => {
            state.raw_data = action.payload.raw_data;
            state.select_data = action.payload.select_data;
            state.loading = false;
            state.error = false;
            return state;
        },
        set_hospitals_error: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
})


export const {
    set_hospitals_loading,
    set_hospitals_data,
    set_hospitals_error,
} = baseHospitalsSlice.actions;

export default baseHospitalsSlice.reducer
import React, {useState} from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useEffect } from 'react';
import { getAuthUser } from './utils'; 
import { login } from './redux/slices/userSlice';

import { Provider } from 'react-redux';

import store from './redux/store';
import BaseRoutes from './routes/BaseRoute';

// notifications alerts
import 'react-notifications-component/dist/theme.css'
import ReactNotification from 'react-notifications-component'
// notifications alerts

//basepopup
import BasePopup, {BasePopupContext} from "./components/BasePopup";
//basepopup




function App() {

  // state for popup
  const initialPopupState = {
    component: "",
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false
  }
  const [popupState, setPopupState] = useState(initialPopupState);

  const openPopup = (params) => setPopupState(old => ({...old, ...params}));

  const global_popup_context_data = {
    popupState,
    setPopup: openPopup
  }
  // state for popup

  // this effect runs on first time page load.
  // it determines whether we have a user in storage or not.
  // if we do have it, we change the state and set the user.
  // else we keep the user state empty by default. 

  useEffect(() => {
    let authUser = getAuthUser();
    if(authUser){
      store.dispatch(login(authUser));
    }
  }, []);

  return (

    
     <>
      <Provider store = {store}>
        {/* wrapping my content inside base popup */}
        <BasePopupContext.Provider value={global_popup_context_data}>
          <BrowserRouter>
            <ReactNotification />
            <BasePopup/>
            {/* main application routes here */}
            <BaseRoutes />
            {/* main application routes here */}
          </BrowserRouter>
        </BasePopupContext.Provider>
        {/* wrapping my content inside base popup */}
      </Provider>
      </>
  );
}

export default App;

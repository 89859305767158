import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set_disclaimer } from '../../../redux/slices/disclaimerSlice';
import { setDisclaimerSession, setDisclaimerLocal } from '../../../utils';
import { BasePopupContext } from '../../BasePopup';
import { HAART_PROCEDUREs_RELEASE } from '../../../constants';

const HaartSurgeonProcedure = () => {
    const { setPopup } = useContext(BasePopupContext);
    const [showPoup, setShowPopup] = useState(()=>{
        let disclaimerLocal=localStorage.getItem(HAART_PROCEDUREs_RELEASE)
        let disclaimerSession = sessionStorage.getItem(HAART_PROCEDUREs_RELEASE);
        if(disclaimerLocal || disclaimerSession){
            return false
        }else{
            return true
        }
    });

    


    const openPopup = () => {
        // setting component details
        const popup = {
            component: <Container setShowPopup={setShowPopup} />,
            visible: true,
            heading: "New Release",
            size: "large",
            contained: true,
            hideDefaultClose: true,
        }
        // setting popup state
        setPopup(popup);
    }

    useEffect(() => {
        if (showPoup) {
            openPopup();
        }
        return () => {
            setPopup({ visible: false });
        }
    }, [])

    return (
        <>
        </>
    )
}

export default HaartSurgeonProcedure;

const Container = ({setShowPopup}) => {
    const { setPopup } = useContext(BasePopupContext);


    const setDisclaimerLocal = () => {
        localStorage.setItem(HAART_PROCEDUREs_RELEASE, JSON.stringify(false));
        setPopup({ visible: false });
        setShowPopup(false)

    }
    const setDisclaimerSession = () => {
        sessionStorage.setItem(HAART_PROCEDUREs_RELEASE, JSON.stringify(false));
        setPopup({ visible: false });
        setShowPopup(false)
    }

    return (
        <div className='text-justify'>
            <div className='text-justify'>
                <p className="text-base text-justify mb-3 text-red-primary font-bold">
                    Dear User,
                </p>
                <p className="text-base mb-2">
                In alignment with the <span className='text-blue-primary font-medium'>QOP-85-13 HAART Surgeon Training Procedure</span>  we have implemented the following key enhancements in CSPRO:
                    
                </p>

                <ul className='list-disc px-8 pt-3'>
                    <li className="text-base mb-2 font-bold text-red-primary">
                        <span className='text-black font-normal'>New HAART Proctorship Training Log forms are now available in CSPRO (accessible through the "Blank Forms Download" section).</span>
                    </li>
                    <li className="text-base mb-2 font-bold text-red-primary">
                        <span className='text-black font-normal'>Selection of who signed the Training Log and possibility to upload the form either manually or sending via email.</span>
                    </li>
                    <li className="text-base mb-2 font-bold text-red-primary">
                        <span className='text-black font-normal'>Possibility to include the email from the proctor testifying successful proctorship completion in case the case was supported remotely.</span>
                    </li>
                </ul>



                <p className="text-base py-4">
                    Thank you for your continued partnership in ensuring our CSPRO platform remains updated and comprehensive, ready to meet auditing requirements.
                </p>

                <p className='text-red-primary font-bold pt-3 my-2'>Sincerely,</p>
                <p>Global Medical affairs and CSPRO teams</p>
            </div>

            <div className="flex justify-end">
                <button
                    className="bg-red-primary disabled:bg-gray-light text-sm rounded-md text-white px-5 py-2 md:py-2 mr-3 mt-3"
                    onClick={() => {
                        setDisclaimerLocal()
                        
                    }}
                >
                    Don't show again
                </button>
                <button
                    className="bg-blue-primary disabled:bg-gray-light text-sm rounded-md text-white px-5 py-2 md:py-2 mr-3 mt-3"
                    onClick={() => {
                        setDisclaimerSession();
                    }}
                >
                    Understood
                </button>
            </div>
        </div>
    )
}


import {createSlice} from "@reduxjs/toolkit";
import { STATISTICS_CORCYM_EXPERT_DROPDOWN, ALL_ACTIVITIES_DROPDOWN_SECOND, HV_DROPDOWN, PRODUCT_DROPDOWN_DEFAULT, ZONE_DROPDOWN_DEFAULT, COUNTRY_DROPDOWN_DEFAULT, DATE_FORMAT, PRODUCT_DROPDOWN,SUB_REGION_DROPDOWN_DEFAULT, DEFUALT_STAT_FILTER_DATE } from "../../constants";
import { DateTime } from "luxon";

const initialState = {
    loading: true,
    error: false,
    count: 0, // will increment this if i want to recall the api
    
    parameters: { //this state "parameters" include values that will be used as query params to to call statistics API, yad rkhin khuda da wasta
        hv: HV_DROPDOWN[0],
        is_corcym: STATISTICS_CORCYM_EXPERT_DROPDOWN[0],
        activity: ALL_ACTIVITIES_DROPDOWN_SECOND[0],
        product: PRODUCT_DROPDOWN[0],
        zone: ZONE_DROPDOWN_DEFAULT,
        // start_date: DateTime.now().minus({year: 1}).toFormat(DATE_FORMAT),
        // end_date: DateTime.now().toFormat(DATE_FORMAT),
        // start_date:DateTime.fromISO(DEFUALT_STAT_FILTER_DATE).year - DateTime.now().year > 1 ? DateTime.now().minus({year: 1}).toFormat(DATE_FORMAT): DEFUALT_STAT_FILTER_DATE,
        // end_date: DateTime.fromISO(DEFUALT_STAT_FILTER_DATE).year - DateTime.now().year > 1? DateTime.now().toFormat(DATE_FORMAT): DateTime.now().toFormat(DATE_FORMAT),

        start_date:DEFUALT_STAT_FILTER_DATE,
        end_date:DateTime.now().toFormat(DATE_FORMAT),

        
        old_activities: false,
        country: COUNTRY_DROPDOWN_DEFAULT,
        sub_region: SUB_REGION_DROPDOWN_DEFAULT, //by zain:- to add the value in redux of sub region
    },

    data: {
        start_date: DateTime.now().minus({year: 1}).toFormat(DATE_FORMAT),
        end_date: DateTime.now().toFormat(DATE_FORMAT),
        //dates
        dates: {}
    }
    
}

const budgetSlice = createSlice({
    name: "budget",
    initialState,
    reducers: {
        // parameter reducers
        reset_statistics: (state, action) => {
            state = initialState;
            return state;
        },

        recall_api: (state, action) => {
            state.count = state.count + 1;
            return state;
        },

        set_parameter_field: (state, action) => {
            state.parameters = {...state.parameters, ...action.payload}
            return state;
        },
        // parameter reducers
        
        
        // loading/error reducers
        set_loading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        set_error: (state, action) => {
            state.error = action.payload;
            return state;
        },
        // loading/error reducers

        // main data reducers
        set_stat_data: (state, action) => {
            state.data = {...state.data, ...action.payload}
            return state;
        },
        // main data reducers

    }
})

export default budgetSlice.reducer;

export const {
    reset_statistics,
    recall_api,
    set_parameter_field,
    set_loading,
    set_error,
    set_stat_data,

} = budgetSlice.actions;
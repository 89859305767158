import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: true,
    raw_data: [],
    select_data: [],
    error: false
}

const baseZoneSlice = createSlice({
    name: "base_zones",
    initialState,
    reducers: {
        set_zones_loading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        set_zones_data: (state, action) => {
            state.raw_data = action.payload.raw_data;
            state.select_data = action.payload.select_data;
            state.loading = false;
            state.error = false;
            return state;
        },
        set_zones_error: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
})


export const {
    set_zones_loading,
    set_zones_data,
    set_zones_error,
} = baseZoneSlice.actions;

export default baseZoneSlice.reducer
import React from 'react';
import { logout } from '../redux/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import {success_alert} from "../utils";
import {Link} from 'react-router-dom';

function UserInfoCard() {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const btn = "bg-red-primary py-2 px-3 hover:bg-red-400 text-sm ";

    const logoutUser = () => {
        dispatch(logout(user));
        success_alert("You were logged out successfully!");
    }

    return (
        <>
        <div className = "absolute top-12 left-0 w-72 md:w-96 lg:w-5/5 text-white p-1 pt-1 rounded-sm shadow-md bg-white z-1000">
            <div className = "bg-blue-primary flex flex-col items-center p-8 ">
                <img src="/images/favicon.png" />
                <h1 className="mt-5 text-lg">{user.username} - {user.role}</h1>
            </div>
            <div className ="p-2 flex justify-between" >
                <Link to="/profile"><button className = {btn}>Profile</button></Link>
                <button className={btn} onClick={logoutUser}>Logout</button>
            </div>
        </div>  
        </>
    )
}

export default UserInfoCard

import React, { useState } from 'react';
import "./TopBar.css";
import {DateTime} from "luxon";
// Icons Import
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";

// Components Import
import UserInfoCard from '../UserInfoCard';


// detects click outside component
import { useDetectClickOutside } from 'react-detect-click-outside';

import { useSelector } from 'react-redux';


function Topbar({asideVisibility, setAsideVisibility,userInfoCard, setUserInfoCard}) {

    const user = useSelector(state => state.user);

    // const [userInfoCard, setUserInfoCard] = useState(false);

    const outsideRef = useDetectClickOutside({ onTriggered: () =>  setUserInfoCard(false)});

   
    const showInfoCard = () => {
        setUserInfoCard(!userInfoCard);
    }
    return (
        <>
        {/* logo for small screen */}
        <div className="block lg:hidden bg-white h-16 flex items-center justify-center">
            <img src = "/images/logo.png" draggable={false} className="h-full select-none"/> 
        </div>
        {/* logo for small screen */}
        <div className = "pr-4 lg:px-4 flex-shrink-0 flex bg-gray-light justify-between w-full items-center relative h-14 lg:h-16">

            {/* profile dropdown */}
            <div className="lg:px-2 lg:py-2 hover:bg-gray-sidebar text-blue-primary cursor-pointer relative flex h-full lg:h-auto lg:block items-center" onClick = {() => setUserInfoCard(true)} ref={outsideRef}>
                
                <div className="flex  items-center px-2">
                    <div className="h-8 w-8  rounded-full bg-no-repeat bg-cover bg-center" style={{backgroundImage: "url('/images/favicon.png')"}}>
                    </div>
                    <h1 className="hidden lg:block ml-3 text-xs 2xl:text-sm " >
                        Welcome {user.username} - Today is {DateTime.now().toFormat("dd/MM/yyyy")}
                    </h1>
                    <span >
                        <RiArrowDropDownLine className="ml-2 text-2xl"/>
                    </span>
                        
                </div> 

                {
                    userInfoCard ?
                    <UserInfoCard />
                    :
                    null
                }
                
            </div>
            {/* profile dropdown */}

            <div onClick={() => setAsideVisibility(!asideVisibility)}
            className="z-10000 relative lg:hidden h-full flex items-center cursor-pointer">
                <span className = "text-lg text-blue-primary">
                    {asideVisibility ? 
                        <FaTimes />
                    :
                        <GiHamburgerMenu />
                    }
                </span>
            </div>
            
           

            <img src = "/images/logo.png" draggable={false} className="h-full hidden lg:block select-none"/> 
            
        </div>
            
        </>
    )
}

export default Topbar

import React, {Suspense} from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import {FullScreenLoading} from "../components/Loading"

const SupportListing = React.lazy(() => import("../pages/SupportListing"));
const SupportDetail = React.lazy(() => import("../pages/SupportDetail"));

function TechnicalAdminRoutes(props) {
  const location = useLocation()

    return (
        <Suspense fallback={<FullScreenLoading/>}>
          <Switch>

          {/* SUPPORT */}
          <Route exact path="/support">
            <SupportListing key={location.key}/>  
          </Route>
          <Route exact path="/support/:id">
            <SupportDetail key={location.key}/>  
          </Route>
          {/* SUPPORT */}

          
          <Redirect from="/" to="/support"/>
          
          </Switch>
        </Suspense>
    )
}

export default TechnicalAdminRoutes


import { AiFillDashboard } from "react-icons/ai";
import { GoGraph } from "react-icons/go";
import { BsCircle } from "react-icons/bs";
import { BiCalendarPlus, BiBarChartAlt } from "react-icons/bi";
import { BiCalendarCheck } from "react-icons/bi";
import { AiTwotoneFilePdf } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa";
import { FaUserMd } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import { FaBook } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";

export const technicalAdminLinks = [
    {
        heading: true,
        title:"SUPPORT",
        level: 1,
    },
    {
        heading: false,
        title:"Ask for support",
        icon: <FaEnvelope />,
        to : '/support',
        nested: false,
        level: 1,
    },
]

import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: true,
    error: false,

    // whether alternative proposal form is open or not
    alternative_open: false,
    // whether alternative proposal form is open or not

    // whether edit_date form is open or not
    edit_date_open: false,
    // whether edit_date form is open or not

    // whether the butons are loading or not
    status_loading: false,
    // whether the butons are loading or not

    // The data of proctorship, EXCLUDING the alternative proposals, goes in the "primary_data" key
    primary: false,
    // The data of proctorship, EXCLUDING the alternative proposals, goes in the "primary_data" key

    // all statuses and alternatives go inside here
    status: [],
    // all statuses and alternatives go inside here

    //every time we want to reload the full page, we simply change this state bu incrementing or decrementing
    count: 0,
    //every time we want to reload the full page, we simply change this state bu incrementing or decrementing

    // all trainees go inside this arr
    trainees: [],
    // all trainees go inside this arr
    
    //all feedback forms relating to trainees go here
    trainees_feedback: [],
    //all feedback forms relating to trainees go here

    //all feedback forms relating to proctors go here
    memo_family_feedback: null,
    perceval_feedback:null,
    solo_smart_feedback: null,
    haart_product_feedback: null,
    //all feedback forms relating to proctors go here

    // activity attendence form
    attendence_form:null,
    // activity attendence form

    // activity certification form for perceval proctorship
    certification_form:[],
    // activity certification form for perceval proctorship


    // activity attendence form
    invoice_form:null,
    // activity attendence form

    // fetch sales managers
    sales_managers:[]
    // fetch sales managers

}

const proctorshipActivitySlice = createSlice({
    name: "proctorship_activity",
    initialState,
    reducers: {
        set_loading: (state, action) => {
            state.loading = action.payload;
            return state
        },
        set_error: (state, action) => {
            state.error = action.payload;
            return state
        },
        
        set_primary_data: (state, action) => {
            state.primary = action.payload;
            return state
        },

        set_status: (state, action) => {
            state.status = action.payload;
            return state;
        },

        set_alternative_visibility: (state, action) => {
            state.alternative_open = !state.alternative_open;
            return state;
        },
        
        set_alternative_visibility_false: (state, action) => {
            state.alternative_open = false;
            return state;
        },
        
        set_edit_date_visibility: (state, action) => {
            state.edit_date_open = !state.edit_date_open;
            return state;
        },
        
        set_edit_date_visibility_false: (state, action) => {
            state.edit_date_open = false;
            return state;
        },
        
        increment_count: (state, action) => {
            state.count = state.count + 1;
            return state;
        },

        set_status_loading: (state, action) => {
            state.status_loading = action.payload;
            return state
        },
        
        set_trainees: (state, action) => {
            state.trainees = action.payload;
            return state
        },
        set_trainees_feedback: (state, action) => {
            state.trainees_feedback = action.payload;
            return state
        },
        set_memo_family_feedback: (state, action) => {
            state.memo_family_feedback = action.payload;
            return state
        },
        set_solo_smart_feedback: (state, action) => {
            state.solo_smart_feedback = action.payload;
            return state
        },
        set_haart_product_feedback: (state, action) => {
            state.haart_product_feedback = action.payload;
            return state
        },
        set_perceval_feedback: (state, action) => {
            state.perceval_feedback = action.payload;
            return state
        },
        set_attendance_form: (state, action) => {
            state.attendence_form = action.payload;
            return state
        },
        set_certification_form: (state, action) => {
            state.certification_form = action.payload;
            return state
        },
        set_invoice_form: (state, action) => {
            state.invoice_form = action.payload;
            return state
        },
        set_sales_manager: (state, action) => {
            state.sales_managers = action.payload;
            return state
        },
        

    }
})

export const {
    set_loading,
    set_error,
    set_primary_data,
    set_status,
    set_alternative_visibility,
    set_alternative_visibility_false,
    set_edit_date_visibility,
    set_edit_date_visibility_false,
    increment_count,
    set_status_loading,
    set_trainees,
    set_trainees_feedback,
    set_memo_family_feedback,
    set_solo_smart_feedback,
    set_haart_product_feedback,
    set_perceval_feedback,
    set_attendance_form,
    set_certification_form,
    set_invoice_form,
    set_sales_manager
} = proctorshipActivitySlice.actions;

export default proctorshipActivitySlice.reducer;
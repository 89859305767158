import React from 'react'
import { useHistory } from "react-router-dom";

function Footer() {
    const history = useHistory();
    return (
        <div className="flex-shrink-0 px-3 py-2 flex items-center border-t-2 w-full text-sm md:text-base">
            <p className ="text-black font-bold">Copyright © 2024 CORCYM. All rights reserved. VERSION RELEASED <span className='underline cursor-pointer text-red-primary' onClick={()=>history.push('/new-release')} >MAR 2024</span> Credits: 
                <a className="text-blue-primary font-normal ml-3">Kaffeina Srl.</a></p>
        </div>
    )
}

export default Footer

import React, {useState, useEffect} from 'react'
import {FaCaretLeft, FaCaretDown} from "react-icons/fa";
import {NavLink} from "react-router-dom"

import ThirdLevelLink from "./ThirdLevelLink";

const SecondLevelLink = ({closeSidebar, link}) => {
    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState(0);

    
    const increaseHeight = () => {
        setOpen(true);
        setHeight(link.links.length * 40);
    }
    
    const decreaseHeight = () => {
        setOpen(false);
        setHeight(0);
    }

    return (
        <div className="w-12 m-0 w-full bg-gray-bg-dark" >
            <p onClick={() => !open ? increaseHeight() : decreaseHeight()} className="border-l-4 hover:border-blue-primary cursor-pointer pl-3 pr-3 m-0 no-underline text-gray-textdark sidebar-link-height flex items-center justify-between">
                <span className="text-sm flex items-center">
                    <span className="text-base mr-2">
                        {link.icon}
                    </span>
                    {link.title}
                </span>
                <div className="text-sm flex-shrink-0">
                {open ? <FaCaretDown/> : <FaCaretLeft/>}
                </div>
            </p>
            <div className={`border-l-1 border-gray-light bg-gray-bg-darker overflow-hidden relative transition-all`} style={{height: `${height}px`}}>
                
                {/* second level */}
                {link.links.map(second_link => (
                    second_link.nested ?
                        <ThirdLevelLink closeSidebar={closeSidebar} second_link={second_link} setParentHeight={setHeight} parentHeight={height} />
                    : 
                        <NavLink to={second_link.to} activeClassName="bg-black bg"  onClick={closeSidebar} className="hover:text-white pl-5 pr-3 m-0 no-underline text-gray-light sidebar-link-height flex items-center justify-between">
                            <span className="text-sm flex items-center">
                                <span className="text-base mr-2">
                                    {second_link.icon}
                                </span>
                                {second_link.title}
                            </span>
                        </NavLink>
                ))}
                {/* second level */}
                
                
            </div>
            
        </div>
    )
}

export default SecondLevelLink

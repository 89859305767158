import {createSlice} from "@reduxjs/toolkit";
import { ALL_ACTIVITIES_DROPDOWN, HV_DROPDOWN, PRODUCT_DROPDOWN_DEFAULT, ZONE_DROPDOWN_DEFAULT, COUNTRY_DROPDOWN_DEFAULT, DATE_FORMAT, PRODUCT_DROPDOWN, STATISTICS_CORCYM_EXPERT_DROPDOWN,
    SUB_REGION_DROPDOWN_DEFAULT,
    DEFUALT_STAT_FILTER_DATE,
    AREA_DROP_DOWN,
    HAART_PRODUCT_DROPDOWN
} from "../../constants";
import { DateTime } from "luxon";

const initialState = {
    loading: true,
    error: false,
    speaking_loading: true,
    speaking_error: false,
    count: 0, // will increment this if i want to recall the api
    speaking_count: 0, // will increment this if i want to recall the api
    
    parameters: { //this state "parameters" include values that will be used as query params to to call statistics API, yad rkhin khuda da wasta
        hv: HV_DROPDOWN[0],
        is_corcym: STATISTICS_CORCYM_EXPERT_DROPDOWN[0],
        activity: ALL_ACTIVITIES_DROPDOWN[0],
        product: PRODUCT_DROPDOWN[0],
        haart_product:HAART_PRODUCT_DROPDOWN[2],
        zone: ZONE_DROPDOWN_DEFAULT,
        // start_date: DateTime.now().minus({year: 1}).toFormat(DATE_FORMAT),
        // end_date: DateTime.now().toFormat(DATE_FORMAT),
        // start_date:DateTime.fromISO(DEFUALT_STAT_FILTER_DATE).year - DateTime.now().year > 1 ? DateTime.now().minus({year: 1}).toFormat(DATE_FORMAT): DEFUALT_STAT_FILTER_DATE,
        // end_date: DateTime.fromISO(DEFUALT_STAT_FILTER_DATE).year - DateTime.now().year > 1? DateTime.now().toFormat(DATE_FORMAT): DateTime.now().toFormat(DATE_FORMAT),

        start_date:DEFUALT_STAT_FILTER_DATE,
        end_date:DateTime.now().toFormat(DATE_FORMAT),


        old_activities: false,
        country: COUNTRY_DROPDOWN_DEFAULT,
        sub_region: SUB_REGION_DROPDOWN_DEFAULT , //by zain:- to add the value in redux of sub region
        area:AREA_DROP_DOWN[0],
    },

    speaking_parameters: { //this state "parameters" include values that will be used as query params to to call statistics API, yad rkhin khuda da wasta
        zone: ZONE_DROPDOWN_DEFAULT,
        // start_date: DateTime.now().minus({year: 1}).toFormat(DATE_FORMAT),
        // end_date: DateTime.now().toFormat(DATE_FORMAT),
        // start_date:DateTime.fromISO(DEFUALT_STAT_FILTER_DATE).year - DateTime.now().year > 1 ? DateTime.now().minus({year: 1}).toFormat(DATE_FORMAT): DEFUALT_STAT_FILTER_DATE,
        // end_date: DateTime.fromISO(DEFUALT_STAT_FILTER_DATE).year - DateTime.now().year > 1? DateTime.now().toFormat(DATE_FORMAT): DateTime.now().toFormat(DATE_FORMAT),

        start_date:DEFUALT_STAT_FILTER_DATE,
        end_date:DateTime.now().toFormat(DATE_FORMAT),
        
        country: COUNTRY_DROPDOWN_DEFAULT,
        sub_region: SUB_REGION_DROPDOWN_DEFAULT //by zain:- to add the value in redux of sub region
        
    },


    data: {
        trainee_by_region: [],
        trainee_by_region_old: [],
        training_activity: [],
        training_activity_old: [],
        activity_type: {},

        //speaking charts below
        solution_covered: [],
        solution_covered_previous_year:[],

        speaking_region: [],
        speaking_region_previous_year:[],

        speech_focus: [],
        speech_focus_previous_year: [],

        speaking_event_on_mics: {},
        speaking_event_on_mics_previous_year:{},


        
        trainee_interested_in_mics: {},
        trainee_interested_in_mics_previous_year:{},
        start_date: DateTime.now().minus({year: 1}).toFormat(DATE_FORMAT),
        end_date: DateTime.now().minus({year: 1}).toFormat(DATE_FORMAT),
        //dates
        dates: {}
    }
    
}

const statisticsMedicalSlice = createSlice({
    name: "stat_medical",
    initialState,
    reducers: {
        // parameter reducers
        reset_statistics: (state, action) => {
            state = initialState;
            return state;
        },

        recall_api: (state, action) => {
            state.count = state.count + 1;
            return state;
        },
        recall_speaking_api: (state, action) => {
            state.speaking_count = state.speaking_count + 1;
            return state;
        },

        set_parameter_field: (state, action) => {
            state.parameters = {...state.parameters, ...action.payload}
            return state;
        },
        
        set_speaking_parameter_field: (state, action) => {
            state.speaking_parameters = {...state.speaking_parameters, ...action.payload}
            return state;
        },
        // parameter reducers
        
        
        // loading/error reducers
        set_loading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        set_error: (state, action) => {
            state.error = action.payload;
            return state;
        },
        set_speaking_loading: (state, action) => {
            state.speaking_loading = action.payload;
            return state;
        },
        set_speaking_error: (state, action) => {
            state.speaking_error = action.payload;
            return state;
        },
        // loading/error reducers

        // main data reducers
        set_stat_data: (state, action) => {
            state.data = {...state.data, ...action.payload}
            return state;
        },
        // main data reducers

    }
})

export default statisticsMedicalSlice.reducer;

export const {
    reset_statistics,
    recall_api,
    recall_speaking_api,
    set_parameter_field,
    set_speaking_parameter_field,
    set_loading,
    set_error,
    set_speaking_loading,
    set_speaking_error,
    set_stat_data,

} = statisticsMedicalSlice.actions;
import { DateTime } from "luxon";

// export const BASE_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_URL : process.env.REACT_APP_DEVELOPMENT_URL;
// export const BASE_URL="https://cspro-local-api.astutesoftwares00.com/"// development
export const BASE_URL = 'https://api.corcym-cspro.com/' // production
// export const BASE_URL = 'http://192.168.18.47:8002/' // Tayyab
// REACT_APP_DEVELOPMENT_URL="http://192.168.18.171:8005/"

// activity screens
export const DEFAULT_ACTIVITY_SCREEN = "default-activity-screen";
export const ACTIVITY_PROCTOR_SCREEN = "activity-proctor-screen";
export const ACTIVITY_MASTERPROCTORSHIP_SCREEN = "activity-masterproctor-screen";
export const ACTIVITY_PRECEPTORSHIP_SCREEN = "activity-preceptor-screen";
export const ACTIVITY_SPEAKINGEVENTS_SCREEN = "activity-speakingevent-screen";
// activity screens

//mics course constants
export const MICS_COURSE_SCREEN = "mics-course-screen";
export const MINISTERNOTOMY_SELECTION = "ministernotomy";
export const MINISTERNOTOMY_LABEL = "Ministernotomy course";
export const RAT_SELECTION = "rat";
export const RAT_LABEL = "Rat course";
//mics course constants


// listing limits
export const LISTING_LIMIT = 15;
// listing limits

// date display format - to be used in DateTime's /toFormat/ method
// export const DATE_FORMAT = "yyyy-MM-dd"
export const DATE_FORMAT = "yyyy-MM-dd"
export const DATE__DISPLAY_FORMAT = "dd-MM-yyyy"

export const MM_DD_YEAR = "MM-dd-yyyy" //this format will be use only for US/NORTH AMERICA/CANDA zone users
// date display format - to be used in DateTime's /toFormat/ method

// roles
export const SUPER_ADMIN = "super_admin";
export const TECHNICAL_ADMIN = "technical_admin";
export const LOCAL_ADMIN = "local_admin";
export const SALES_MANAGER = "sales_manager";
// roles


// proctorship constants start

export const TYPE_OF_TRAINING = "types-of-training";
export const TYPE_OF_TRAINING_FIRST = "first-training";
export const TYPE_OF_TRAINING_ADVANCED = "advanced-training";

export const TYPE_OF_FIRST_TRAINING = "types-of-first-training";
export const TYPE_OF_FIRST_TRAINING_NEW_CENTER = "to-open-new-center";
export const TYPE_OF_FIRST_TRAINING_CENTER_OPENED = "center-already-open-new-surgeon";
export const TYPE_OF_FIRST_TRAINING_PROCTORSHIP = "first-proctorship";
export const TYPE_OF_FIRST_TRAINING_IMPLANT = "second-implant";


export const TYPE_OF_ADVANCED_TRAINING = "types-of-advanced-training";
export const TYPE_OF_ADVANCED_TRAINING_OTHER = "other-advanced-training";
export const TYPE_OF_ADVANCED_TRAINING_COMPLAINT = "training-after-a-complaint";
export const TYPE_OF_ADVANCED_TRAINING_FOLLOW_UP = "follow-up-proctorship-after-10-cases";
export const TYPE_OF_ADVANCED_REFRESH_TRAINING = 'refresh-training'


export const OTHER_TRAINING_REGULAR = "the-surgeon-implants";
export const OTHER_TRAINING_NOT_REGULAR = "the-surgeon-does-not-implants-regularly";

export const TRAINEE_TITLE_ARR = [
    {value: "surgeon", label: "Surgeon"},
    {value: "anesthesiologist", label: "Anesthesiologist"},
    {value: "nurse", label: "Nurse"},
    {value: "perfusionist", label: "Perfusionist"},
    {value: "other", label: "Other"},
];

export const TRAINEE_MVR_ARR = [
    {value: "current-preferential-avr-mvr-approach", label: "Current preferential AVR/ MVR approach"},
    {value: "full-sternotomy", label: "Full Sternotomy"},
    {value: "medianmini-strenotomy", label: "Median/mini sternotomy"},
    {value: "rat", label: "RAT/minithoracotomy"},
    {value: "endoscopic", label: "Endoscopic"},




]

// proctorship constants end

// masterproctorship constants

export const TRAINING_NEW_CERTIFICATION = "certification-of-a-new-proctor"; 
export const TRAINING_ADVANCED_TRAINING = "advanced-training-with-a-masterproctor"; 

// masterproctorship constants

// statuses
export const STATUS_ALTERNATIVE_PROPOSAL = "alternative-proposal"; 
export const STATUS_CANCELLED = "cancelled"; 
export const STATUS_CLOSED = "closed";
export const STATUS_CONFIRMED = "confirmed";
export const STATUS_PAST_DUE = "past-due";
export const STATUS_PROCESSING = "processing";
export const STATUS_PENDING = "pending"; 
export const STATUS_WAITING_FOR_DOCS = "waiting-for-docs"; 
// statuses


// speaking event constants
// export const PEER_TO_PEER_EVENT_TYPE = "speaking-at-corcym-webinar-hcp-peer-to-peer-event"
export const PEER_TO_PEER_EVENT_TYPE = "peer-to-peer-virtual"
export const CORCYM_Webinar = "corcym-webinar"
export const PEER_TO_PEER_EVENT_TYPE_IN_PERSON = "peer-to-peer-in-person-example-proctor-visit-to-a-customer-for-training-not-including-a-proctorship"
export const OTHER_EVENT="others"
export const EVENT_TYPE = [
    // {
    //     "label": "Speaking at corcym webinar (HCP virtual meetings)",
    //     "value": "speaking-at-corcym-webinar-hcp-virtual-meetings"
    // },
    // {
    //     "label": "Speaking at corcym webinar (HCP Peer-to-Peer event)",
    //     "value": PEER_TO_PEER_EVENT_TYPE //making it a variable because we need to apply some checks on it.
    // },
    // {
    //     "label": "Speaking / Training at corcym event (Patient education program)",
    //     "value": "speaking-training-at-corcym-event-patient-education-program"
    // },
    // {
    //     "label": "Speaking / Training at third party event (HCP education)",
    //     "value": "speaking-training-at-third-party-event-hcp-education"
    // },
    // {
    //     "label": "Speaking / Training at third party event (Patient education)",
    //     "value": "speaking-training-at-third-party-event-patient-education"
    // },
    // {
    //     "label": "Speaking / Training at Corcym Event - Symposium (At third party / Congress)",
    //     "value": "speakingtraining-at-corcym-event-symposium-at-third-party"
    // },
    // ADDTION BY ZAIN
    {
        "label": "Peer to peer (in-person)*",
        "value": PEER_TO_PEER_EVENT_TYPE_IN_PERSON  //making it a variable because we need to apply some checks on it.
    },
    {
        "label": "Peer to peer (virtual)*",
        "value": PEER_TO_PEER_EVENT_TYPE //making it a variable because we need to apply some checks on it.
    },
    {
        "label": "CORCYM Webinar",
        "value": CORCYM_Webinar //making it a variable because we need to apply some checks on it.
    },
    {
        "label": "CORCYM In person event for HCPs (example: focus group, advisory board)",
        "value": "corcym-in-person-event-for-hcps-example-focus-group-advisory-board"
    },
    {
        "label": "CORCYM sponsored event within a third party congress / meeting (example Symposium within a congress)",
        "value": "corcym-sponsored-event-within-a-third-party-congress-meetingexample-symposium-within-an-congress"
    },
    // Other is not a requiremnet i have add this just for manage data dumping by Ashar
    {
        "label": "others",
        "value": OTHER_EVENT
    },
    // Other is not a requiremnet i have add this just for manage data dumping by Ashar
]

// speaker role
export const SPEAKER_ROLE_SPEAKER = "speaker";
export const SPEAKER_ROLE_MODERATOR = "moderator";
export const SPEAKER_ROLE_PARTICIPANT_ADVISORY = "partecipant-advisory-board";
export const SPEAKER_ROLE_HCP_ENGAGED = "hcp-engaged-no-presentation-required";
// speaker role

//specialty
export const SPECIALTY_SURGEON = "surgeon";
export const SPECIALTY_ANESTHESIOLOGIST = "anesthesiologist";
export const SPECIALTY_NURSES = "nurses";
export const SPECIALTY_PERFUSIONIST = "perfusionist";
export const SPECIALTY_CORCYM_EMPLOYEE = "corcym-employee";
export const SPECIALTY_OTHER = "other";

//specialty

// speaking event constants

// virtual and in perosn proctorship constants
export const IS_ONLINE = "is_online"
export const IS_OFFLINE = "is_offline"
// Clinical Trainer or proctor expert
export const CORCYM_EXPERT_PROCTORSHIP = "Clinical Trainer" 
export const PROCTOR_EXPERT_PROCTORSHIP = "Proctor" 


//statistics constants that will be used on different statistics pages
export const VIRTUAL_PROCTORSHIP = "virtual-proctorship";
export const VIRTUAL_MASTERPROCTORSHIP = "virtual-masterproctorship";
export const CORYM_EXPERTS = "corcym";
export const PROCTORS = "proctors";


export const ALL_ACTIVITIES_DROPDOWN = [
    {value:"", label: "All Activities"},
    {value:"proctorship", label: "Proctorship"},
    // {value: VIRTUAL_PROCTORSHIP, label:"Virtual Proctorship"},
    {value:"masterproctorship", label: "Masterproctorship"},
    // {value:VIRTUAL_MASTERPROCTORSHIP, label: "Virtual Perceval Masterproctorship"},
    {value:"perceptership", label: "Preceptorship", color: "red"},
    // {value:"speaking-event", label: "Spekaing Events"},
    // {value:"mics-course", label: "MICS Course"},
]
export const ALL_ACTIVITIES_DROPDOWN_IN_EFFICACY = [
    {value:"", label: "All Activities"},
    {value:"proctorship", label: "Proctorship"},
    {value:"masterproctorship", label: "Masterproctorship"},
    {value:"perceptership", label: "Preceptorship", color: "red"},
]

export const ALL_ACTIVITIES_DROPDOWN_SECOND = [
    {value:"", label: "All Activities"},
    {value:"proctorship", label: "Proctorship"},
    {value:"masterproctorship", label: "Perceval Masterproctorship"},
    {value:"preceptorship", label: "Preceptorship", color: "red"},
]

export const HV_DROPDOWN = [
    {value:"", label: "All"},
    {value:"hv", label: "HV"},
]

export const STATISTICS_CORCYM_EXPERT_DROPDOWN = [
    {value:"", label: "All"},
    {value:"true", label: "Clinical Trainer"},
    {value:"false", label: "Proctors"},
]
export const AREA_DROP_DOWN = [
    {value:"", label: "Format: (all)"},
    {value:true, label: "Virtual "},
    {value:false, label: "In-Person"},
]
export const CITY_SCAN_DROP_DOWN = [
    {value:"", label: "CT scan"},
    {label: "Yes", value: true},
    {label: "No", value: false},
]



export const SUB_REGION_DROPDOWN_CHECK = [
    {value: "international", label:"International"},
    {value: "north-america", label:"North America"},
    {value: "china", label:"China"},


]

// export const ZONE_DROPDOWN_DEFAULT = {value:"", label: "Global"};
export const ZONE_DROPDOWN_DEFAULT = {value:"", label: "All Zones"};
export const PRODUCT_DROPDOWN_DEFAULT = {value:"", label: "All Products"};
// export const COUNTRY_DROPDOWN_DEFAULT = {value:"", label: "All Countries"};
export const COUNTRY_DROPDOWN_DEFAULT = {value:"", label: "Select Country/SubRegions"};
export const SUB_REGION_DROPDOWN_DEFAULT = {value:"", label: "Select Country"};


export const SELECT_CUSTOM_STYLE_SMALL = {
    control: base => ({
      ...base,
      height: 30,
      minHeight: 30
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
      }),
  
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
  };

export const ZONE_DROPDOWN_TABLE_STAT = [
    // {label: "Europe", value:1},
    // {label: "INTERNATIONAL SUM", value:47},
    // {label: "North America", value:42},
    // {label: "Japan", value:36},
    {label: "Europe", value:1},
    {label: "LATAM", value:2},
    {label: "JAPAN/ANZ", value:3},
    {label: "INTERNATIONAL", value:4},
    {label: "North America", value:5},
    {label: "CHINA", value:6},

]

export const EVENT_STAT_DROPDOWN = [
    {
        label: "All Events",
        value: ""
    },
    {
        label: "Corcym Event",
        value: "corcym-event"
    },
    {
        label: "Third Party Event",
        value: "third-party-event"
    },
]

export const SOLUTIONS_STAT_DROPDOWN = [
    {
        label: "All Solutions",
        value: ""
    },
    {
        "label": "Aortic Perceval",
        "value": "Aortic Perceval"
    },
    {
        "label": "Aortic Crown",
        "value": "Aortic Crown"
    },
    {
        "label": "Mitral Memo Family",
        "value": "Mitral Memo Family"
    },    

    {
        "label": "Aortic: Solo Smart",
        "value": "Aortic: Solo Smart"
    },   
    {
        "label": "Bicarbon",
        "value": "Bicarbon"
    },   
    {
        "label": "Carbomedics",
        "value": "Carbomedics"
    },
    {
        "label": "HAART",
        "value": "haart"
    },   
    {
        "label": "Others",
        "value": "Others"
    },   
    
]

export const SPEAKERS_STAT_DROPDOWN = [
    {
        label: "All Speakers",
        value: ""
    },
    {
        label: "HCPs engaged only",
        value: "all"
    },
    {
        label: "Corcym employee engaged also",
        value: "corcym-employee"
    },
]

export const MIN_MAX_STAT_DROPDOWN = [
    {
        label: "All Sizes",
        value: {
            min: "",
            max: ""
        }
    },
    {
        label: "0-5",
        value: {
            min: 0,
            max: 5
        }
    },
    {
        label: "6-20",
        value: {
            min: 6,
            max: 20
        }
    },
    {
        label: "21-50",
        value: {
            min: 21,
            max: 50
        }
    },
    {
        label: "> 50",
        value: {
            min: 50,
            max: ""
        }
    },
]

export const NUMBER_OF_CASES=[ //Perceval Certification Cases
    {
        label:"1",
        value:1
    },
    {
        label:"2",
        value:2
    },
    {
        label:"3",
        value:3
    },
    {
        label:"4",
        value:4
    },
    {
        label:"5",
        value:5
    },
]

export const SPEACIFIC_EVENT_STAT_DEFAULT = {
    label: "Specific Event",
    value: ""
}
//statistics constants that will be used on different statistics pages

export const RATING_ARR_FOR_MICS = [
    {value:"poor",label:"Poor"},
    {value:"Average", label:"Average"},
    {value:"Good", label:"Good"},
    {value:"Excellent",label:"Excellent"}
]

export const BOOL_OPTIONS = [
    {label: "Yes", value: true},
    {label: "No", value: false},
]

export const DISCLAIMER = "disclaimer_cspro";
export const RELEASE_PROCTORSHIP_TRAINING_LOG = "release-proctorship-trainig-log"
export const HAART_PROCEDUREs_RELEASE="haart-procedure-release"
export const NOTIFCATION = "notification_cs";

export const SPEAKING_EVENT_FIXED_PARITICIPANTS = [
    {value: 1, label: "1"},
    {value: 2, label: "2"},
    {value: 3, label: "3"},
    {value: 4, label: "4"},
    {value: 5, label: "5"},
]


// These following constant will be use for US/CANDA/North America zone ppls.
export const US_ZONE="US"
export const CANADA_ZONE="Canada"
export const NORT_AMERICA_ZONE ="North America"

export const UNC_ZONES=[US_ZONE,CANADA_ZONE,NORT_AMERICA_ZONE]


export const ENG_LANGUAGE ="English"
export const FRENCH_LANGUAGE="French"


export const NOT_APPLICABLE="Not Applicable"


export const INTERNATIONAL_ZONE="INTERNATIONAL"
// export const DEFUALT_STAT_FILTER_DATE=`${DateTime.now().year - 1 }-01-01`
export const DEFUALT_STAT_FILTER_DATE=`2023-01-01`



export const PERCEVAL_PRODUCT_NAME="Perceval"  //Perceval product Name
export const PERCEVAL_PRODUCT="perceval"  //Perceval product slug


export const HAART_PRODUCT_CONST = "HAART" //only for frontend use
// HAART PRODUCTS CODEs
export const HEART_200 = 'haart-200'   
export const HEART_300 = 'haart-300'  
export const HEART_BOTH = 'both'
// HEART PRODUCTS CODEs


// HAART PRODUCTS Names
export const HEART_BOTH_NAME = 'Both'
export const HEART_BOTH_NAME_FOR_FORNTEND = 'HAART Both'
export const HEART_200_NAME = 'HAART 200'
export const HEART_300_NAME = 'HAART 300'
// HAART PRODUCTS Names


// HAART PRODUCTS ids
export const HEART_200_ID = 4    
export const HEART_300_ID = 5    
export const HEART_BOTH_ID = 6 
// HAART PRODUCTS ids

export const HAART_PRODUCTS=[ HEART_200, HEART_300, HEART_BOTH] 
export const HAART_PRODUCTS_IDS=[ HEART_200_ID, HEART_300_ID, HEART_BOTH_ID] 
export const HAART_PRODUCTS_NAMEs=[ HEART_200_NAME, HEART_300_NAME, HEART_BOTH_NAME,HEART_BOTH_NAME_FOR_FORNTEND] 


export const SOLO_SMART_PRODUCT = "solo-smart"



export const RED_PRIMARY_CODE ="#860034"
export const RED_SECONDARY_CODE ="#dd4b39"
export const GRAY_PRIMARY_CODE ="#484848"


export const BLUE_PRIMARY_CODE ="#043c7b"
export const BLUE_SECONDARY_CODE ="#2674b6"




export const BILEAFLET_INSUFFICIENCY ="Bileaflet insufficiency"
export const TRILEAFLET_INSUFFICIENCY ="Trileaflet insufficiency"
export const CONGENTIAL_DEFECT ="Congenital defect"



export const PRODUCT_DROPDOWN = [
    {value:"", label: "All Products"},
    {value:"perceval", label: "Perceval"},
    {value:"solo-smart", label: "Solo Smart"},
    {value:"memo-family", label: "Memo Family"},
    // {value:HEART_200, label: "HAART 200"},
    // {value:HEART_300, label: "HAART 300"},
    {value:HEART_BOTH , label:"HAART"}
]


export const HAART_PRODUCT_DROPDOWN = [
    {value:HEART_200, label: "HAART 200"},
    {value:HEART_300, label: "HAART 300"},
    {value:HEART_BOTH , label:"All"}
]


export const ECO_TTE="TTE" //only in case for haart products for CT-scan field in procotrship
export const ECO_TEE="TEE" //only in case for haart products for CT-scan field in procotrship


export const BICUSPID = "bicuspid"
export const BICUSPID_COMPLICATED = "bicuspid-complicated"
export const CONGENITAL = "congenital"


export const regularRadioForHaart = [
    { value: BICUSPID, label: "Bicuspid" },
    { value: BICUSPID_COMPLICATED, label: "Bicuspid, Complicated" },
    { value: CONGENITAL, label: "Congenital" },
]

export const HAART_PROCTORSHIP_TRAINING_LOG_CODE = "CC-MK-001312 A"
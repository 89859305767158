import React, { Suspense } from 'react'
import { Switch,Route, Redirect } from 'react-router-dom';

import {FullScreenLoading} from "../components/Loading";

const Login = React.lazy(() => import("../pages/Login"));

const ActivateAccount = React.lazy(() => import("../pages/ActivateAccount/ActivateAccount"));
const ForgotPassword = React.lazy(() => import("../pages/ActivateAccount/ForgotPassword"));
const RecoverPassword = React.lazy(() => import("../pages/ActivateAccount/RecoverPassword"));

const EventFeedbackForm = React.lazy(() => import("../pages/Activities/SpeakingEvent/RightSide/SpeakingInformation/EventFeedBackForm/EventFeedbackForm"));
const EventFeedbackSummary = React.lazy(() => import("../pages/Activities/SpeakingEvent/RightSide/SpeakingInformation/EventFeedBackForm/EventFeedbackSummary"));

const ProctorshipSoloMemoFeedback = React.lazy(() => import("../pages/Activities/Proctorship/RightSide/ProductFeedback/MemoSolo"));
const ProctorshipPercevalFeedback = React.lazy(() => import("../pages/Activities/Proctorship/RightSide/ProductFeedback/ForPerceval"));
const ProctorshipHaartFeedback = React.lazy(() => import("../pages/Activities/Proctorship/RightSide/ProductFeedback/HaartProduct"));
const PercevalCenterCertification = React.lazy(() => import("../pages/Activities/Proctorship/RightSide/ProctorshipCertificationForm/SignatureForm"));
const PercevalTrainerCenterCertification = React.lazy(() => import("../pages/Activities/Proctorship/RightSide/ProctorshipCertificationForm/PercevalTrainer/SignatureForm"));
const OtherTrainerCenterCertification = React.lazy(() => import("../pages/Activities/Proctorship/RightSide/ProctorshipCertificationForm/OtherTrainer/SignatureForm"));

const TraineeReportForm = React.lazy(() => import("../pages/Activities/Proctorship/RightSide/TraineeFeedbackFormWithLink"));
const TraineeReportFormForHaart = React.lazy(() => import("../pages/Activities/Proctorship/RightSide/TraineeFeedbackFormWithLinkForHaart"));

const PreceptorshipTraineeReportForm = React.lazy(() => import("../pages/Activities/Preceptorship/RightSide/FeedbackWithLink"));
const PreceptorshipHaartTraineeReportForm = React.lazy(() => import("../pages/Activities/Preceptorship/RightSide/FeedbackWithLinkForHaart"));

const MasterProctorFeedbcakForm = React.lazy(() => import("../pages/Activities/MasterProctorship/RightSide/FeedbackWithLinks/masterFeedback"));
const MasterTraineeReportForm = React.lazy(() => import("../pages/Activities/MasterProctorship/RightSide/FeedbackWithLinks/proctorFeedback/"));
const MicsPreceptorshipTraineeReportForm = React.lazy(() => import("../pages/MicsCourseActivity/Preceptorship/RightSide/TraineeFeedbackLink"));
const FirstProctFeedbackFormPerceval = React.lazy(() => import("../pages/MicsCourseActivity/FirstProctorship/RightSide/Reports/ProductsFeedbackLink"));
const TraineeReportFormFirstProct = React.lazy(() => import("../pages/MicsCourseActivity/FirstProctorship/RightSide/Reports/TraineeFeedBackLink"));


// second proctorship
const SecondProctFeedbackFormPerceval = React.lazy(() => import("../pages/MicsCourseActivity/SecondProctorship/RightSide/Reports/ProductsFeedbackLink"));
const TraineeReportFormSecondProct = React.lazy(() => import("../pages/MicsCourseActivity/SecondProctorship/RightSide/Reports/TraineeFeedBackLink"));

function AuthRoute({setIsFeedback}) {

    return (
        <Suspense fallback={<FullScreenLoading/>}>
            <Switch>
                <Route path="/auth" exact>
                    <Login />
                </Route>

                <Route path="/activate-account/:token" exact>
                    <ActivateAccount />
                </Route>

                <Route path="/recover-password/:token" exact>
                    <RecoverPassword />
                </Route>

                <Route path="/forgot-password" exact>
                    <ForgotPassword />
                </Route>

                <Route path="/feedback/:id" exact>
                    <EventFeedbackForm />
                </Route>

                <Route path="/feedbackSummary/:id" exact>
                    <EventFeedbackSummary />
                </Route>

                {/* added by zain */}
        
        {/* proctorfeedback */}
          <Route path='/product-feedback/:id/:product' exact>
            <ProctorshipSoloMemoFeedback setIsFeedback={setIsFeedback}/>
          </Route>
          <Route path= '/perceval-product-feedback/:id/:product' exact>
            <ProctorshipPercevalFeedback setIsFeedback={setIsFeedback}/>
          </Route>

          <Route path= '/trainee-feedback/:id/:traineeId/:product' exact>
          <TraineeReportForm setIsFeedback={setIsFeedback}/>
          </Route>

          {/*proctorship  Haart feedback */}

          <Route path='/haart-product-feedback/:id/:product' exact>
            <ProctorshipHaartFeedback setIsFeedback={setIsFeedback}/>
          </Route>

          <Route path= '/trainee-feedback_haart/:id/:traineeId/:product' exact>
            <TraineeReportFormForHaart setIsFeedback={setIsFeedback}/>
          </Route>

          {/*proctorship  Haart feedback */}


          {/* <Route path='/perceval-center-certification-trainee/:id/:proctorshipID' exact>
          <PercevalCenterCertification setIsFeedback={setIsFeedback}/>
          </Route> */}
          <Route path='/perceval-center-certification-proctor/:id/:proctorshipID' exact>
            <PercevalCenterCertification setIsFeedback={setIsFeedback} is_proctor={true} />
          </Route>
          <Route path='/perceval-trainer-center-certification/:proctorshipID' exact>
            <PercevalTrainerCenterCertification setIsFeedback={setIsFeedback}/>
          </Route>
          <Route path='/other-trainer-center-certification/:proctorshipID' exact>
            <OtherTrainerCenterCertification setIsFeedback={setIsFeedback} is_haart={false}/>
          </Route>
          {/* For HAART CENTER CERTIFICATION */}
          <Route path='/haart-center-certification-proctor/:id/:proctorshipID/:product' exact>
            <PercevalCenterCertification setIsFeedback={setIsFeedback} is_proctor={true} is_haart={true}/>
          </Route>
          <Route path='/other-trainer-haart-center-certification/:proctorshipID/:product' exact> 
            <OtherTrainerCenterCertification setIsFeedback={setIsFeedback} is_haart={true}/>
          </Route>
          {/* For HAART CENTER CERTIFICATION */}




          {/* preceptorshipfeedback link */}
          <Route path= '/preceptorship-feedback/:id/:traineeId/:product' exact> {/*PName=> Product Name*/}
             <PreceptorshipTraineeReportForm setIsFeedback={setIsFeedback} />
          </Route>
          <Route path= '/preceptorship-haart-feedback/:id/:traineeId/:product' exact> {/*PName=> Product Name*/}
             <PreceptorshipHaartTraineeReportForm setIsFeedback={setIsFeedback} />  
          </Route>

          {/* MasterProctorfeedback link */}
          <Route path= '/masterproctor-feedback/:masproctId' exact> {/* masproctID=> for masterTrainee */ }
          <MasterProctorFeedbcakForm setIsFeedback={setIsFeedback}/>
          </Route>
          <Route path= '/masterproctor-trainee-feedback/:mastproctId/:traineeId' exact>
               {/* masproctID=> for masterProctorshipActivity, traineettId=> for Traineee in masterproctorship */ }
          <MasterTraineeReportForm setIsFeedback={setIsFeedback}/>
          </Route>
          
          {/* mics course feedback */}
          <Route path= '/mics-preceptorship-report/:id/:traineeId' exact>
          <MicsPreceptorshipTraineeReportForm setIsFeedback={setIsFeedback}/>
          </Route>
          {/* Firts Proct */}
          <Route path= '/mics-first-proctor-perceval-report/:firstProctId/mics/:micsId' exact>
          <FirstProctFeedbackFormPerceval setIsFeedback={setIsFeedback}/>
          </Route>
          <Route path= '/mics-first-proctor-trainee-report/:firstProctId/:traineeId/mics/:micsId' exact>
          <TraineeReportFormFirstProct setIsFeedback={setIsFeedback}/>
          </Route>
          {/* Second Proct */}
          <Route path= '/mics-second-proctor-perceval-report/:SecondProctId/mics/:micsId' exact>
          <SecondProctFeedbackFormPerceval setIsFeedback={setIsFeedback}/>
          </Route>
          <Route path= '/mics-second-proctor-trainee-report/:SecondProctId/:traineeId/mics/:micsId' exact>
          <TraineeReportFormSecondProct setIsFeedback={setIsFeedback}/>
          </Route>



                <Redirect from="/" to="/auth" />
            </Switch>
        </Suspense>
    )
}

export default AuthRoute

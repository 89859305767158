import { createSlice } from '@reduxjs/toolkit';

import {getAuthUser, removeDate, showNotification} from '../../utils';


const initialState = getAuthUser();

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        login: (state, action) => (action.payload),
        logout: (state,action) => {

            state = false;
            localStorage.removeItem('access_token');
            // showNotification();
            removeDate();
            return state;
        } 
    }
})

export const {login,logout} = userSlice.actions;
export default userSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { getDisclaimer } from "../../utils";

const initialState = {
    show: getDisclaimer(),
}

const disclaimerSlice = createSlice({
    name: "disclaimer",
    initialState,
    reducers: {
        set_disclaimer: (state, action) => {
            state.show = action.payload;
            return state;
        }
    }
})

export default disclaimerSlice.reducer;

export const {
    set_disclaimer
} = disclaimerSlice.actions;
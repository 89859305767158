import {createSlice} from "@reduxjs/toolkit";

const initialState = {

    //id
    id: 0,
    //id
    
    //id
    is_rat: false,
    //id

    //screens, determine which screen we should show
    screen: 0,
    //screens, determine which screen we should show

    // we use this for initial loading and main page errors
    loading: true,
    error: false,
    // we use this for initial loading and main page errors

    // we use this for determining whether a feedback was uploaded or not
    feedback: null,
    // we use this for determining whether a feedback was uploaded or not

    // whether the butons are loading or not
    status_loading: false,
    // whether the butons are loading or not

    //every time we want to reload the full page, we simply change this state by incrementing or decrementing
    count: 0,
    //every time we want to reload the full page, we simply change this state by incrementing or decrementing

    preceptorship: {
        // whether alternative proposal form is open or not
        alternative_open: false,
        // whether alternative proposal form is open or not

        // whether edit_date form is open or not
        edit_date_open: false,
        // whether edit_date form is open or not

        // The data of proctorship, EXCLUDING the alternative proposals, goes in the "primary_data" key
        primary: false,
        // The data of proctorship, EXCLUDING the alternative proposals, goes in the "primary_data" key

        // all statuses and alternatives go inside here
        status: [],
        // all statuses and alternatives go inside here

        // all trainees go inside this arr
        trainees: [],
        // all trainees go inside this arr

        //all feedback forms relating to trainees go here
        trainees_feedback: [],
        //all feedback forms relating to trainees go here

        // activity attendence form
        attendence_form:null,
        // activity attendence form

        // activity attendence form
        invoice_form:null
        // activity attendence form
    },

    first_proctorship: {
        // determines whether we added this proctorship or not
        is_empty: true,
        // determines whether we added this proctorship or not

        // whether alternative proposal form is open or not
        alternative_open: false,
        // whether alternative proposal form is open or not

        // whether edit_date form is open or not
        edit_date_open: false,
        // whether edit_date form is open or not

        // The data of proctorship, EXCLUDING the alternative proposals, goes in the "primary_data" key
        primary: false,
        // The data of proctorship, EXCLUDING the alternative proposals, goes in the "primary_data" key

        // all statuses and alternatives go inside here
        status: [],
        // all statuses and alternatives go inside here

        //all feedback forms relating to trainees go here
        trainees_feedback: [],
        //all feedback forms relating to trainees go here

        //all feedback forms relating to proctors go here
        perceval_feedback:null,
        //all feedback forms relating to proctors go here

        // activity attendence form
        attendence_form:null,
        // activity attendence form

        // activity attendence form
        invoice_form:null,
        // activity attendence form

        // activity certification form for perceval proctorship
        certification_form:null,
        // activity certification form for perceval proctorship
    },
    
    second_proctorship: {
        // determines whether we added this proctorship or not
        is_empty: true,
        // determines whether we added this proctorship or not

        
        // whether alternative proposal form is open or not
        alternative_open: false,
        // whether alternative proposal form is open or not

        // whether edit_date form is open or not
        edit_date_open: false,
        // whether edit_date form is open or not

        // The data of proctorship, EXCLUDING the alternative proposals, goes in the "primary_data" key
        primary: false,
        // The data of proctorship, EXCLUDING the alternative proposals, goes in the "primary_data" key

        // all statuses and alternatives go inside here
        status: [],
        // all statuses and alternatives go inside here

        //all feedback forms relating to trainees go here
        trainees_feedback: [],
        //all feedback forms relating to trainees go here

        //all feedback forms relating to proctors go here
        perceval_feedback:null,
        //all feedback forms relating to proctors go here

        // activity attendence form
        attendence_form:null,
        // activity attendence form

        // activity attendence form
        invoice_form:null,
        // activity attendence form

        // activity certification form for perceval proctorship
        certification_form:null,
        // activity certification form for perceval proctorship
    },

    // this state is used to populate values when creating first or second proctorship
    proctorship_form: {
        start_date: "",
        end_date: "",
        country: "",
        hospital: "",
        suggested_hotel: "",
        number_of_cases: "",
        procedure_time: ""
    },
    // this state is used to populate values when creating first or second proctorship
    
    // all trainees go inside this arr
    trainees: [],
    // all trainees go inside this arr

}

const micsCourseActivitySlice = createSlice({
    name: "mics_course_activity",
    initialState,
    reducers: {
        set_activity_id: (state, action) => {
            state.id = action.payload;
            return state;
        },
        set_is_rat: (state, action) => {
            state.is_rat = action.payload;
            return state;
        },
        set_feedback: (state, action) => {
            state.feedback = action.payload;
            return state;
        },
        // screen states
        set_screen: (state, action) => {
            state.screen = action.payload;
            return state;
        },
        // screen states

        // top level states for mics
        set_loading: (state, action) => {
            state.loading = action.payload;
            return state
        },
        set_error: (state, action) => {
            state.error = action.payload;
            return state
        },
        increment_count: (state, action) => {
            state.count = state.count + 1;
            return state;
        },

        set_status_loading: (state, action) => {
            state.status_loading = action.payload;
            return state
        },
        // top level states for mics

        // ****************PRECEPTORSHIP PRECEPTORSHIP PRECEPTORSHIP***********************
        set_preceptorship_primary_data: (state, action) => {
            state.preceptorship.primary = action.payload;
            return state
        },

        set_preceptorship_status: (state, action) => {
            state.preceptorship.status = action.payload;
            return state;
        },



        set_preceptorship_alternative_visibility: (state, action) => {
            state.preceptorship.alternative_open = !state.preceptorship.alternative_open;
            return state;
        },
        
        set_preceptorship_alternative_visibility_false: (state, action) => {
            state.preceptorship.alternative_open = false;
            return state;
        },

        

        set_preceptorship_edit_date_visibility: (state, action) => {
            state.preceptorship.edit_date_open = !state.preceptorship.edit_date_open;
            return state;
        },
        
        set_preceptorship_edit_date_visibility_false: (state, action) => {
            state.preceptorship.edit_date_open = false;
            return state;
        },

        // FORMS
        set_preceptorship_trainees_feedback: (state, action) => {
            state.preceptorship.trainees_feedback = action.payload;
            return state
        },
        set_preceptorship_invoice_form: (state, action) => {
            state.preceptorship.invoice_form = action.payload;
            return state
        },

        set_preceptorship_attendence_form: (state, action) => {
            state.preceptorship.attendence_form = action.payload;
            return state
        },
        // FORMS

        // ****************PRECEPTORSHIP PRECEPTORSHIP PRECEPTORSHIP***********************


        
        // ****************FIRST_PROCTORSHIP FIRST_PROCTORSHIP FIRST_PROCTORSHIP***********************
        set_first_proctorship_primary_data: (state, action) => {
            state.first_proctorship.primary = action.payload;
            return state
        },
        
        set_first_proctorship_is_empty: (state, action) => {
            state.first_proctorship.is_empty = action.payload;
            return state
        },

        set_first_proctorship_status: (state, action) => {
            state.first_proctorship.status = action.payload;
            return state;
        },

        set_first_proctorship_alternative_visibility: (state, action) => {
            state.first_proctorship.alternative_open = !state.first_proctorship.alternative_open;
            return state;
        },
        
        set_first_proctorship_alternative_visibility_false: (state, action) => {
            state.first_proctorship.alternative_open = false;
            return state;
        },

        

        set_first_proctorship_edit_date_visibility: (state, action) => {
            state.first_proctorship.edit_date_open = !state.first_proctorship.edit_date_open;
            return state;
        },
        
        set_first_proctorship_edit_date_visibility_false: (state, action) => {
            state.first_proctorship.edit_date_open = false;
            return state;
        },


        // FORMS
        set_first_proctorship_trainees_feedback: (state, action) => {
            state.first_proctorship.trainees_feedback = action.payload;
            return state
        },
        
        set_first_proctorship_perceval_feedback: (state, action) => {
            state.first_proctorship.perceval_feedback = action.payload;
            return state
        },
        set_first_proctorship_certification_form: (state, action) => {
            state.first_proctorship.certification_form = action.payload;
            return state
        },
        
        set_first_proctorship_invoice_form: (state, action) => {
            state.first_proctorship.invoice_form = action.payload;
            return state
        },

        set_first_proctorship_attendence_form: (state, action) => {
            state.first_proctorship.attendence_form = action.payload;
            return state
        },
        // FORMS
        // ****************FIRST_PROCTORSHIP FIRST_PROCTORSHIP FIRST_PROCTORSHIP***********************
        
        
        
        // ****************SECOND_PROCTORSHIP SECOND_PROCTORSHIP SECOND_PROCTORSHIP***********************
        set_second_proctorship_primary_data: (state, action) => {
            state.second_proctorship.primary = action.payload;
            return state
        },

        set_second_proctorship_is_empty: (state, action) => {
            state.second_proctorship.is_empty = action.payload;
            return state
        },

        set_second_proctorship_status: (state, action) => {
            state.second_proctorship.status = action.payload;
            return state;
        },

        set_second_proctorship_alternative_visibility: (state, action) => {
            state.second_proctorship.alternative_open = !state.second_proctorship.alternative_open;
            return state;
        },
        
        set_second_proctorship_alternative_visibility_false: (state, action) => {
            state.second_proctorship.alternative_open = false;
            return state;
        },

        

        set_second_proctorship_edit_date_visibility: (state, action) => {
            state.second_proctorship.edit_date_open = !state.second_proctorship.edit_date_open;
            return state;
        },
        
        set_second_proctorship_edit_date_visibility_false: (state, action) => {
            state.second_proctorship.edit_date_open = false;
            return state;
        },



        // FORMS
        set_second_proctorship_trainees_feedback: (state, action) => {
            state.second_proctorship.trainees_feedback = action.payload;
            return state
        },
        
        set_second_proctorship_perceval_feedback: (state, action) => {
            state.second_proctorship.perceval_feedback = action.payload;
            return state
        },
        set_second_proctorship_certification_form: (state, action) => {
            state.second_proctorship.certification_form = action.payload;
            return state
        },
        
        set_second_proctorship_invoice_form: (state, action) => {
            state.second_proctorship.invoice_form = action.payload;
            return state
        },

        set_second_proctorship_attendence_form: (state, action) => {
            state.second_proctorship.attendence_form = action.payload;
            return state
        },
        // FORMS
        // ****************SECOND_PROCTORSHIP SECOND_PROCTORSHIP SECOND_PROCTORSHIP***********************



        // ****************PROCTORSHIP_FORM PROCTORSHIP_FORM PROCTORSHIP_FORM***********************
        set_proctorship_form_field: (state, action) => {
            state.proctorship_form = {...state.proctorship_form, ...action.payload};
            return state;
        },
        reset_proctorship_form: (state, action) => {
            state.proctorship_form = initialState.proctorship_form;
            return state;
        },
        // ****************PROCTORSHIP_FORM PROCTORSHIP_FORM PROCTORSHIP_FORM***********************
        
        // ****************TRAINEES TRAINEES TRAINEES***********************
        set_trainees: (state, action) => {
            state.trainees = action.payload;
            return state
        },
        // ****************TRAINEES TRAINEES TRAINEES***********************


    }
})

export const {
    // base
    set_is_rat,
    set_feedback,
    set_activity_id,
    set_screen,
    set_loading,
    set_error,
    increment_count,
    set_status_loading,
    // base


    // preceptorship
    set_preceptorship_primary_data,
    set_preceptorship_status,

    set_preceptorship_alternative_visibility,
    set_preceptorship_alternative_visibility_false,

    set_preceptorship_edit_date_visibility,
    set_preceptorship_edit_date_visibility_false,

    // forms
    set_preceptorship_trainees_feedback,
    set_preceptorship_invoice_form,
    set_preceptorship_attendence_form,
    // forms
    // preceptorship

    // first_proctorship
    set_first_proctorship_primary_data,
    set_first_proctorship_status,
    set_first_proctorship_is_empty,

    set_first_proctorship_alternative_visibility,
    set_first_proctorship_alternative_visibility_false,

    set_first_proctorship_edit_date_visibility,
    set_first_proctorship_edit_date_visibility_false,

    // forms
    set_first_proctorship_trainees_feedback,
    set_first_proctorship_perceval_feedback,
    set_first_proctorship_certification_form,
    set_first_proctorship_invoice_form,
    set_first_proctorship_attendence_form,
    // forms
    // first_proctorship


    // second_proctorship
    set_second_proctorship_primary_data,
    set_second_proctorship_status,
    set_second_proctorship_is_empty,

    set_second_proctorship_alternative_visibility,
    set_second_proctorship_alternative_visibility_false,

    set_second_proctorship_edit_date_visibility,
    set_second_proctorship_edit_date_visibility_false,

    // forms
    set_second_proctorship_trainees_feedback,
    set_second_proctorship_perceval_feedback,
    set_second_proctorship_certification_form,
    set_second_proctorship_invoice_form,
    set_second_proctorship_attendence_form,
    // forms
    // second_proctorship

    //proctorship form
    set_proctorship_form_field,
    reset_proctorship_form,
    //proctorship form

    //trainees
    set_trainees

} = micsCourseActivitySlice.actions;

export default micsCourseActivitySlice.reducer;
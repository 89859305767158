import { configureStore } from '@reduxjs/toolkit';

// Slice imports

// user 
import userReducer from './slices/userSlice';
// user 

// proctorship 
import proctorShipReducer from './slices/proctorshipSlice';
// proctorship 

// masterproctorship 
import masterProctorShipReducer from './slices/masterProctorshipSlice';
// masterproctorship 

// preceptorship 
import preceptorshipReducer from './slices/preceptorshipSlice';
// preceptorship 

// speakingEvents 
import speakingEventReducer from './slices/speakingEventSlice';
// speakingEvents 

// zone list
import zoneListReducer from "./slices/zoneListSlice"
// zone list

// user list
import userListReducer from "./slices/userListSlice"
// user list

// base countries
import baseCountriesReducer from "./slices/baseCountriesSlice";
// base countries

// base Products
import baseProductsReducer from "./slices/baseProductsSlice";
// base Products

// base Zones
import baseZonesReducer from "./slices/baseZonesSlice";
// base Zones
// base hospitals
import baseHospitalsReducer from "./slices/baseHospitalsSlice";
// base hospitals

// base Edit Hospital
import baseEditHospitalReducer from './slices/baseEditHospital';
// base Edit Hospital


// base approaches
import baseApproachesReducer from "./slices/baseApproachesSlice";
// base approaches

// base experties
import baseExpertiesReducer from "./slices/baseExpertiseSlice";
// base experties

// base language
import baseLanguageReducer from "./slices/baseLanguagesSlice";
// base language

// base proctor
import baseProctorsReducer from "./slices/baseProctorsSlice";
// base proctor

// activity reducers
import proctorshipActivityReducer from "./slices/proctorshipActivitySlice";
import masterproctorshipActivityReducer from "./slices/masterproctorshipActivitySlice";
import preceptorshipActivityReducer from "./slices/preceptorshipActivitySlice";
import speakingEventActivityReducer from "./slices/speakingEventActivitySlice";
// activity reducers

// course reducers
import micsCourseReducer from "./slices/micsCourseSlice"
// course reducers

// mics course activity reducer
import micsCourseActivityReducer from "./slices/micsCourseActivitySlice"
// mics course activity reducer

import disclaimerReducer from "./slices/disclaimerSlice";


//statistics 
import statisticsMedicalReducer from "./slices/statisticsMedicalSlice";
import percevalGrowthReducer from "./slices/percevalGrowthSlice";
import haartGrowthSliceReducer from "./slices/haartGrowthSlice";
import haartEfficacySliceReducer from "./slices/haartEfficacySlice";
import haartEfficiencySliceReducer from "./slices/haartEfficiencySlice";

import percevalEfficacyReducer from "./slices/percevalEfficacySlice";
import percevalEfficiencyReducer from "./slices/percevalEfficiencySlice";
import speakingGrowthReducer from "./slices/speakingGrowthSlice";
import speakingEfficacyReducer from "./slices/speakingEfficacySlice";
import budgetReducer from "./slices/budgetSlice";
//statistics 

import proctorshipCertificationReducer from './slices/proctorshipCertificationSlice';
import ProfessionalParticipantReducer from './slices/ProfessionalParticipantSlice';
// Slice imports


export default configureStore({
    reducer:{
        // auth user
        user: userReducer,
        // auth user

        // zone_list
        zone_list: zoneListReducer,
        // zone_list

        // user_list
        user_list: userListReducer,
        // user_list

        // base_countries
        base_countries: baseCountriesReducer,
        // base_countries
        
        // base_products
        base_products: baseProductsReducer,
        // base_products

        // base_zones
        base_zones: baseZonesReducer,
        // base_zones

        // base_hospitals
        base_hospitals: baseHospitalsReducer,
        // base_hospitals
        
        // base_edit_hospitals
        base_edit_hospital:baseEditHospitalReducer,
        // base_edit_hospitals

        // base_consultant_approaches
        base_approaches: baseApproachesReducer,
        // base_consultant_approaches

        // base_consultant_experties
        base_experties: baseExpertiesReducer,
        // base_consultant_experties
        
        // base_consultant_experties
        base_language: baseLanguageReducer,
        // base_consultant_experties

        // base_proctors_experties
        base_proctors: baseProctorsReducer,
        // base_proctors_experties

        // ACTIVITIES CREATE
        
        // proctorship
        proctorship: proctorShipReducer,
        // proctorship

        // masterproctorship
        masterproctorship: masterProctorShipReducer,
        // masterproctorship

        //preceptorship
       preceptorship:preceptorshipReducer,
        //preceptorship

        //speakingEvent
       speaking_event:speakingEventReducer,
        //speakingEvent

        // ACTIVITIES CREATE


        // activities
        proctorship_activity: proctorshipActivityReducer,
        masterproctorship_activity: masterproctorshipActivityReducer,
        preceptorship_activity: preceptorshipActivityReducer,
        speaking_event_activity: speakingEventActivityReducer,
        // activities

        //course
        mics_course: micsCourseReducer,
        //course

        // mics course activity
        mics_course_activity: micsCourseActivityReducer,
        // mics course activity


        //statistics
        stat_medical: statisticsMedicalReducer,
        perceval_growth: percevalGrowthReducer,
        haart_growth: haartGrowthSliceReducer,
        haart_efficacy: haartEfficacySliceReducer,
        haart_efficiency: haartEfficiencySliceReducer,

        perceval_efficacy: percevalEfficacyReducer,
        perceval_efficiency: percevalEfficiencyReducer,
        speaking_growth: speakingGrowthReducer,
        speaking_efficacy: speakingEfficacyReducer,
        budget: budgetReducer,
        //statistics

        proctorship_certification: proctorshipCertificationReducer,
        disclaimer: disclaimerReducer,

        profesional_Participant:ProfessionalParticipantReducer
    } 
})



import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: true,
    error: false,

    // whether event proposal form is open or not
    event_open: false,
    // whether event proposal form is open or not

    // whether edit_date form is open or not
    edit_date_open: false,
    // whether edit_date form is open or not

    // whether the butons are loading or not
    status_loading: false,
    // whether the butons are loading or not

    // The data of speaking event, EXCLUDING the speaking event, goes in the "primary" key
    primary: false,
    // The data of speaking event, EXCLUDING the speaking event, goes in the "primary" key

    // all statuses and event go inside here
    status: [],
    // all statuses and event go inside here

    //every time we want to reload the full page, we simply change this state bu incrementing or decrementing
    count: 0,
    //every time we want to reload the full page, we simply change this state bu incrementing or decrementing

    // all speech go inside this arr
    speech: [],
    // all speech go inside this arr

    // all speech go inside this arr
    hospital: [],
    // all speech go inside this arr

    // speech form data
    add_speech: {
        speaker_role: "",
        specialty: "",
        title: "",
        duration: "",
        speech_focus_id: "",
        focus_not_listed: "",
        proctor_id: "",
        other_proctor: "",
        new_mics: false,
        name_employee: "",
        depart_employee: "",
        notes: "",
        enable_invoice:"",
    },
    // speech form data

     // activity attendence form
     attendence_form:[],
     // activity attendence form

     // activity attendence form
    invoice_form:null,
    // activity attendence form

    professional_participants:{
        specialty: "",
        duration: "",
        focus_not_listed: "",
        other_proctor: "",
        new_mics: "",
        name_employee: "",
        depart_employee: "",
        notes: "",
        enable_invoice: false
    },
    // fetch sales managers
    sales_managers:[]
    // fetch sales managers

}

const speakingEventActivity = createSlice({
    name: "speaking_event_activity",
    initialState,
    reducers: {
        set_loading: (state, action) => {
            state.loading = action.payload;
            return state
        },
        set_error: (state, action) => {
            state.error = action.payload;
            return state
        },
        
        set_primary_data: (state, action) => {
            state.primary = action.payload;
            return state
        },

        set_status: (state, action) => {
            state.status = action.payload;
            return state;
        },

        set_event_visibility: (state, action) => {
            state.event_open = !state.event_open;
            return state;
        },
        
        set_event_visibility_false: (state, action) => {
            state.event_open = false;
            return state;
        },
        
        set_edit_date_visibility: (state, action) => {
            state.edit_date_open = !state.edit_date_open;
            return state;
        },        
        set_edit_date_visibility_false: (state, action) => {
            state.edit_date_open = false;
            return state;
        },

        
        increment_count: (state, action) => {
            state.count = state.count + 1;
            return state;
        },

        set_status_loading: (state, action) => {
            state.status_loading = action.payload;
            return state
        },
        
        set_speech: (state, action) => {
            state.speech = action.payload;
            return state
        },
        set_add_speech_field: (state, action) => {
            state.add_speech = {...state.add_speech, ...action.payload};
            return state;
        },
        set_add_speech_initial: (state, action) => {
            state.add_speech = action.payload
            return state;
        },
        set_professional_perons:(state,action)=>{
            state.professional_participants=action.payload
        },
        
        reset_add_speech: (state, action) => {
            state.add_speech = initialState.add_speech;
            return state;
        },
        set_hospital: (state, action) => {
            state.hospital = action.payload;
            return state
        },
        set_attendance_form: (state, action) => {
            state.attendence_form = action.payload;
            return state
        },
        set_invoice_form: (state, action) => {
            state.invoice_form = action.payload;
            return state
        },
        set_sales_manager: (state, action) => {
            state.sales_managers = action.payload;
            return state
        },
        set_status_of_speech_before_api_call:(state,action)=>{
            const sp=state.speech.findIndex(p=>p.id == action.payload)
            // const specificSpeech=state.speech.filter((p)=>p.id===action.payload)
            state.speech[sp].status=true
            return state

        }

    }
})

export const {
    set_loading,
    set_error,
    set_primary_data,
    set_status,
    set_event_visibility,
    set_event_visibility_false,
    set_edit_date_visibility,
    set_edit_date_visibility_false,
    increment_count,
    set_status_loading,
    set_speech,
    set_hospital,
    set_attendance_form,

    set_add_speech_field,
    set_add_speech_initial,
    reset_add_speech,
    set_invoice_form,
    set_professional_perons,
    set_sales_manager,
    set_status_of_speech_before_api_call
} = speakingEventActivity.actions;

export default speakingEventActivity.reducer;